@import url(./includes/bootstrap.min.css);
@import url(./includes/bootstrap-custom.css);
@import url(normalize.css);
@import url(perfect-scrollbar/css/perfect-scrollbar.css);
@import url(@fancyapps/fancybox/dist/jquery.fancybox.css);
@import url(slick-carousel/slick/slick.css);
@import url(./includes/vendor/slick-theme.css);
@import url(suggestions-jquery/dist/css/suggestions.min.css);
@import url(tippy.js/dist/tippy.css);
@import url(swiper/dist/css/swiper.min.css);
@font-face {
  font-family: 'Avenir Next Cyr';
  src: url("../fonts/AvenirNextCyr-Bold.woff2") format("woff2"), url("../fonts/AvenirNextCyr-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url("../fonts/AvenirNextCyr-Demi.woff2") format("woff2"), url("../fonts/AvenirNextCyr-Demi.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url("../fonts/AvenirNextCyr-Medium.woff2") format("woff2"), url("../fonts/AvenirNextCyr-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url("../fonts/AvenirNextCyr-Heavy.woff2") format("woff2"), url("../fonts/AvenirNextCyr-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url("../fonts/AvenirNextCyr-Regular.woff2") format("woff2"), url("../fonts/AvenirNextCyr-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url("../fonts/AvenirNextCyr-Italic.woff2") format("woff2"), url("../fonts/AvenirNextCyr-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url("../fonts/AvenirNextCyr-UltraLight.woff2") format("woff2"), url("../fonts/AvenirNextCyr-UltraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url("../fonts/AvenirNextCyr-Light.woff2") format("woff2"), url("../fonts/AvenirNextCyr-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

* {
  box-sizing: inherit; }
  *:before, *:after {
    box-sizing: inherit; }
  *:focus {
    outline: none; }

iframe {
  border: none !important; }

img,
video,
audio {
  max-width: 100%;
  height: auto; }

button,
input,
select,
textarea {
  appearance: none;
  border-radius: 0; }

input::-ms-clear,
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0; }

select::-ms-expand {
  display: none; }

label,
button,
select {
  cursor: pointer; }

::selection {
  background-color: rgba(0, 101, 214, 0.5) !important;
  color: #fff !important; }

html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background: #fff;
  color: #1E1F21;
  font-size: 16px;
  font-family: "Avenir Next Cyr", "Helvetica Neue", -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  line-height: 1.5625;
  letter-spacing: 0.09px;
  min-width: 320px;
  margin: 0 auto;
  position: relative; }

.page__layout {
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative; }

main.main {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column; }

.is-ie11 .page__layout {
  display: block; }

.container {
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 20px; }

@media (max-width: 1245px) {
  .container {
    max-width: 920px; } }

@media (max-width: 945px) {
  .container {
    max-width: 720px; } }

@media (max-width: 745px) {
  .container {
    max-width: 100%; } }

.visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

.text-center {
  text-align: center; }

picture {
  display: block;
  font-size: 0;
  line-height: 0; }
  picture img {
    max-width: 100%;
    height: auto; }

a {
  transition: all 0.3s;
  color: #004DA3;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 77, 163, 0.3);
  outline: none; }
  a:focus {
    text-decoration: none;
    outline: none; }
  a:hover {
    text-decoration: none;
    color: #003673;
    border-bottom-color: rgba(0, 77, 163, 0.3); }

p,
ul,
ol,
address,
figure,
blockquote,
table {
  margin: 0;
  margin-bottom: 20px; }

article img {
  margin-bottom: 20px;
  display: block;
  max-width: 100%;
  height: auto; }

blockquote {
  display: block;
  border-left: 2px solid #1F72CF;
  padding-left: 10px; }

hr {
  width: 100%;
  border-top: 0;
  border-bottom-color: rgba(169, 176, 189, 0.3);
  margin: 0; }

ul {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 20px; }
  ul li {
    position: relative;
    padding-left: 13px; }
    ul li:before {
      content: "";
      position: absolute;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #1F72CF;
      left: 0;
      top: 10px; }

ol {
  padding-left: 0;
  list-style: none;
  counter-reset: counter; }
  ol li {
    margin-bottom: 20px;
    position: relative;
    padding-left: 52px;
    counter-increment: counter; }
    ol li:before {
      content: counter(counter);
      position: absolute;
      left: 0;
      width: 32px;
      height: 32px;
      top: -2px;
      border: 1px solid #004DA3;
      border-radius: 50%;
      line-height: 30px;
      font-weight: bold;
      font-size: 14px;
      color: #004DA3;
      letter-spacing: 0.08px;
      text-align: center; }

b, strong {
  font-weight: bold; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin-bottom: 10px;
  letter-spacing: 0.23px;
  line-height: 1.25; }

h1 {
  font-size: 39px;
  line-height: 0.92307692;
  letter-spacing: 0.23px;
  font-weight: 600; }

h2 {
  font-size: 32px;
  line-height: 1.0625;
  letter-spacing: 0.19px; }

h3 {
  font-size: 24px;
  line-height: 1.41666667;
  font-weight: 500;
  letter-spacing: 0.14px; }

h4 {
  font-size: 21px;
  line-height: 1.41666667;
  font-weight: 500; }

h5 {
  font-size: 18px;
  line-height: 1.41666667;
  font-weight: 500; }

h6 {
  font-size: 16px;
  line-height: 1.41666667;
  font-weight: 500; }

@media (max-width: 745px) {
  h1, .h1 {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 0.16px; } }

.blog-article img {
  margin: 28px 0;
  max-width: 100%;
  height: auto; }

.blog-article > p a:not(.p-article-block) {
  color: #00C767;
  border-bottom-color: #00C767;
  font-weight: 600; }
  .blog-article > p a:not(.p-article-block):hover {
    color: #05b861;
    border-bottom-color: #05b861; }

.blog-article p.hightlighted {
  background: #F4F6F9;
  border-radius: 4px;
  padding: 17px 30px;
  padding-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
  color: #47727B;
  letter-spacing: 0;
  line-height: 24px; }

.blog-article p.lead {
  font-size: 24px;
  color: #000000;
  letter-spacing: 0;
  line-height: 34px;
  margin-bottom: 37px; }

.blog-article p, .blog-article ul:not(.article-menu), .blog-article ol, .blog-article address, .blog-article blockquote {
  margin-bottom: 35px; }

.blog-article ul li {
  margin-bottom: 15px; }
  .blog-article ul li:before {
    background: #00C767; }

.blog-article h1,
.blog-article h2,
.blog-article h3,
.blog-article h4,
.blog-article h5,
.blog-article h6 {
  font-weight: bold;
  color: #1E1F21; }

.blog-article h2 {
  font-size: 26px;
  letter-spacing: 0.15px;
  line-height: 1.692308; }

.blog-article h3 {
  font-size: 18px;
  letter-spacing: 0.11px;
  line-height: 1.666667;
  margin-bottom: 10px; }

.blog-article h4 {
  font-size: 16px;
  letter-spacing: 0.09px;
  line-height: 1.6875;
  margin-bottom: 12px; }

.article-menu {
  padding-top: 18px;
  margin-bottom: 45px; }
  .article-menu li {
    padding: 0;
    margin-bottom: 15px; }
    .article-menu li a {
      border: 0; }
    .article-menu li:before {
      display: none; }
    .article-menu li ul {
      margin: 0 !important;
      padding-left: 27px;
      padding-top: 15px; }
  .article-menu > li > a {
    font-weight: bold; }

@media (max-width: 745px) {
  .blog-article p.lead {
    font-size: 20px;
    line-height: 28px; }
  .blog-article h2 {
    font-size: 24px; } }

.bx-pagination ul li:before {
  background: none; }

.main--all-works h1 {
  padding: 40px 0 30px; }

.main--all-works .all-works .row {
  margin-bottom: 20px; }

.main--all-works .all-works .bx-pagination {
  margin-top: 40px; }

.all-works-link {
  text-align: center;
  margin-bottom: -20px;
  margin-top: 20px; }

.btn {
  display: inline-block;
  text-align: center;
  line-height: 1.27272727;
  color: #fff;
  outline: none;
  border: 0;
  background: #1c5dc7;
  border-radius: 5px;
  padding: 17px 27px;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.06px;
  transition: background-color 0.3s, width 0.3s, box-shadow 0.3s;
  z-index: 0;
  text-decoration: none;
  position: relative;
  overflow: hidden; }
  .btn:focus {
    outline: none;
    text-decoration: none; }
  .btn:not(:disabled):hover {
    color: #fff;
    text-decoration: none;
    background: #1F72CF; }

.btn__success {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  color: #fff;
  display: none;
  white-space: nowrap; }

.btn--success {
  background: #2cbf4f;
  cursor: default; }
  .btn--success:not(:disabled):hover {
    background: #2cbf4f; }

.btn--gradient-reverse:before {
  background-image: linear-gradient(-89deg, #0084D0 0%, #004DA3 100%); }

.btn--gradient-reverse:after {
  background-image: linear-gradient(-89deg, #1AA2F0 0%, #1770D2 100%); }

.btn--blue-l {
  background: #3f89dc; }
  .btn--blue-l:not(:disabled):hover {
    background: #1F72CF; }

.btn--green {
  background: #2cbf4f; }
  .btn--green:not(:disabled):hover {
    background: #3be36f; }

.btn--white {
  background: #fff;
  color: #004DA3; }
  .btn--white:not(:disabled):hover {
    background: #fff;
    color: #003673;
    box-shadow: inset 0 0 0 3px #c6d7eb, 0 5px 5px rgba(0, 0, 0, 0.18); }
  .btn--white.btn--border {
    background: none;
    color: #fff;
    border-color: #fff;
    border: 1px solid #fff; }
    .btn--white.btn--border:not(:disabled):hover {
      color: #004DA3;
      background: #fff;
      border-color: #fff;
      box-shadow: none; }

.btn--border {
  background: none;
  color: #2cbf4f;
  border-color: #2cbf4f;
  border: 1px solid #2cbf4f; }
  .btn--border:not(:disabled):hover {
    color: #fff;
    background: #2cbf4f;
    border-color: #2cbf4f;
    box-shadow: none; }
  .btn--border:focus {
    background: none;
    color: #2cbf4f;
    border-color: #2cbf4f;
    border: 1px solid #2cbf4f; }

.btn--border-blue {
  background: none;
  color: #1F72CF;
  border-color: #1F72CF;
  border: 1px solid #1F72CF; }
  .btn--border-blue:not(:disabled):hover {
    color: #fff;
    background: #1F72CF;
    border-color: #1F72CF;
    box-shadow: none; }

.btn--border-white {
  background: none;
  color: #fff;
  border-color: #fff;
  border: 1px solid #fff; }
  .btn--border-white:not(:disabled):hover {
    color: #1F72CF;
    background: #fff;
    border-color: #fff;
    box-shadow: none; }

.btn--lg {
  font-size: 11px;
  padding: 21px 42px;
  letter-spacing: 0.06px; }

.form-field-container .file {
  font-size: 0; }
  .form-field-container .file__label {
    padding-left: 18px;
    position: relative;
    cursor: pointer;
    font-size: 0;
    line-height: 1;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
    margin-top: 2px; }
    .form-field-container .file__label::before {
      content: '';
      position: absolute;
      width: 10px;
      height: 12px;
      background: url(../img/svg-sprites/file.svg) no-repeat 0 0;
      background-size: contain;
      left: 0;
      top: -1px; }
    .form-field-container .file__label span.file__name {
      border: none; }
  .form-field-container .file span, .form-field-container .file a {
    border-bottom: 1px dotted #004DA3;
    font-weight: 500;
    font-size: 11px;
    color: #004DA3;
    letter-spacing: 0.06px;
    text-transform: uppercase;
    max-width: 200px;
    display: inline-block;
    vertical-align: middle;
    text-overflow: ellipsis;
    transition: all 0.3s;
    white-space: nowrap; }
  .form-field-container .file > .file__delete {
    margin-left: 10px;
    margin-top: 2px; }
  .form-field-container .file__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1; }

@media (max-width: 745px) {
  .btn--arrow {
    width: 49px;
    min-width: 49px;
    font-size: 0;
    padding: 0;
    height: 49px; }
    .btn--arrow:before {
      content: "";
      position: absolute;
      top: 50%;
      background: url(../img/svg-sprites/arrow4.svg) no-repeat 0 0;
      background-size: contain;
      margin-left: -5px;
      margin-top: -9px;
      width: 11px;
      height: 19px; } }

.form {
  max-width: 502px;
  margin: 0 auto; }

.form__heading h2, .form__heading .form_title {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 39px;
  letter-spacing: 0.23px;
  line-height: 36px; }

.form__heading h4 {
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 38px; }

.form__heading p {
  margin-bottom: 27px;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400; }

.form__body {
  max-width: 392px;
  margin: 0 auto; }

.form-label {
  line-height: 22px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 7px;
  cursor: default;
  display: inline-block; }
  .form-label--bold {
    font-weight: bold; }
  .form-label small {
    color: #84AFE0;
    font-size: 14px; }

.form-group {
  display: flex;
  justify-content: space-between; }
  .form-group .form-field-container {
    width: calc(50% - 10px); }
  .form-group--single {
    justify-content: center; }

.form-field-container {
  margin-bottom: 20px;
  position: relative;
  background: #FFFFFF;
  border-radius: 5px;
  z-index: 1;
  width: 100%; }
  .form-field-container:before {
    content: "";
    position: absolute;
    width: 13px;
    height: 13px;
    right: 18px;
    top: 50%;
    margin-top: -6px;
    background: url(../img/error.svg) no-repeat 0 0;
    background-size: contain;
    transition: all 0.3s;
    display: none;
    z-index: 1; }
  .form-field-container.has-error .form-field {
    border: 1px solid #FA3939 !important;
    box-shadow: inset 0 0 0 1px #FA3939; }
  .form-field-container.has-success .form-field {
    border: 1px solid #00C767 !important;
    box-shadow: inset 0 0 0 1px #00C767; }
  .form-field-container.is-filled .form-placeholder {
    transform: translateY(-10px); }
  .form-field-container--settlement .form-field, .form-field-container--phone .form-field, .form-field-container--user .form-field, .form-field-container--email .form-field, .form-field-container--password .form-field {
    padding-left: 38px; }
  .form-field-container--settlement .form-placeholder, .form-field-container--phone .form-placeholder, .form-field-container--user .form-placeholder, .form-field-container--email .form-placeholder, .form-field-container--password .form-placeholder {
    padding-left: 38px; }
  .form-field-container--settlement:after, .form-field-container--phone:after, .form-field-container--user:after, .form-field-container--email:after, .form-field-container--password:after {
    content: "";
    position: absolute;
    left: 14px;
    top: 50%;
    margin-top: -7px;
    width: 15px;
    height: 15px; }
  .form-field-container--settlement:after {
    background: url(../img/input_icon_settlement.svg) no-repeat 0 0;
    background-size: contain; }
  .form-field-container--email:after {
    background: url(../img/input_icon_email.svg) no-repeat 0 0;
    background-size: contain;
    width: 16px;
    height: 16px;
    margin-top: -10px; }
  .form-field-container--phone:after {
    background: url(../img/input_icon_phone.svg) no-repeat 0 0;
    background-size: contain; }
  .form-field-container--user:after {
    background: url(../img/input_icon_user.svg) no-repeat 0 0;
    background-size: contain;
    width: 15px;
    height: 16px;
    margin-top: -8px; }
  .form-field-container--password:after {
    background: url(../img/input_icon_password.svg) no-repeat 0 0;
    background-size: contain;
    width: 20px;
    height: 12px;
    margin-top: -5px;
    left: 12px; }

.form-field-container--button {
  display: flex; }
  .form-field-container--button .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    flex: 0 0 auto; }
  .form-field-container--button .form-field {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0; }

.form-field {
  display: block;
  width: 100%;
  height: 49px;
  background: none;
  border: 1px solid #DFDFE0;
  padding: 10px 18px;
  padding-top: 25px;
  color: #1E1F21;
  font-weight: 500;
  border-radius: 5px;
  font-size: 14px;
  position: relative;
  z-index: 1; }
  .form-field--shadow {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05); }
  .form-field:focus {
    outline: none; }
    .form-field:focus ~ .form-placeholder {
      transform: translateY(-10px); }

.form-placeholder {
  color: rgba(30, 31, 33, 0.5);
  transition: all 0.3s;
  font-size: 14px;
  position: absolute;
  padding: 0 18px;
  left: 0;
  top: 50%;
  margin-top: -11px;
  z-index: -1; }
  .form-placeholder span {
    display: none; }

.err-msg {
  font-weight: 500;
  font-size: 13px;
  color: #C60000;
  letter-spacing: 0.08px;
  line-height: 18px;
  padding: 7px 9px;
  background: #FFD3D3;
  border-radius: 4px;
  margin-bottom: 10px; }

.form__button {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center; }
  .form__button .btn--lg {
    padding-left: 35px;
    padding-right: 35px; }
  .form__button .btn {
    white-space: nowrap; }

.form__note {
  font-size: 11px;
  line-height: 14px;
  color: #717D91;
  text-align: center;
  max-width: 334px;
  margin: 0 auto;
  text-align: center; }
  .form__note a {
    color: #717D91;
    border-bottom-color: rgba(122, 112, 150, 0.31); }
    .form__note a:hover {
      color: #7A7096;
      border-bottom-color: rgba(122, 112, 150, 0.5); }

.form__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.form__col {
  width: calc(50% - 10px); }

@media (max-width: 745px) {
  .form {
    max-width: 100%; }
  .form__heading h2, .form__heading .form_title {
    font-size: 28px;
    letter-spacing: 0.16px;
    line-height: 32px;
    margin-bottom: 10px; }
  .form__heading h4 {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 20px; }
  .form__heading p {
    margin-bottom: 29px; }
  .form__body {
    max-width: 100%; } }

.dropdown {
  position: relative;
  display: inline-block;
  line-height: 1; }

.dropdown__menu {
  position: absolute;
  width: 200px;
  max-height: 172px;
  overflow: auto;
  left: -20px;
  top: calc(100% + 8px);
  background: #FFFFFF;
  border: 1px solid #DFDFE0;
  z-index: 101;
  display: none;
  box-shadow: 0 11px 11px rgba(0, 0, 0, 0.08); }

.dropdown__list {
  padding: 4px 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
  font-size: 0; }
  .dropdown__list li {
    padding: 0;
    margin: 0; }
    .dropdown__list li:before {
      display: none; }
    .dropdown__list li a {
      padding: 9px 18px;
      display: block;
      color: #1E1F21;
      border: 0;
      font-size: 14px; }
      .dropdown__list li a:hover, .dropdown__list li a:focus {
        color: #1E1F21;
        background: #edf4f7; }
    .dropdown__list li.is-active a {
      color: #1E1F21;
      background: #edf4f7; }

.dropdown-header-menu {
  position: absolute;
  overflow: auto;
  left: -20px;
  top: 10px;
  z-index: 101;
  display: none; }

.dropdown-header-list {
  padding: 4px 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
  font-size: 0;
  background: #FFFFFF;
  margin-top: 10px; }
  .dropdown-header-list li {
    padding: 0;
    margin: 0; }
    .dropdown-header-list li:before {
      display: none; }
    .dropdown-header-list li a {
      padding: 9px 18px;
      display: block;
      color: #1E1F21;
      border: 0;
      font-size: 14px; }
      .dropdown-header-list li a:hover, .dropdown-header-list li a:focus {
        color: #1E1F21;
        background: #edf4f7; }
    .dropdown-header-list li.is-active a {
      color: #1E1F21;
      background: #edf4f7; }

.nav-item.have-child:hover .dropdown-header-menu {
  display: block; }

.dropdown-header-menu:hover {
  display: block; }

.btn--blue:focus,
.btn--green:focus,
.btn--blue-l:focus,
.btn--gradient:focus {
  color: #fff; }

.btn--border-blue:focus,
.btn--white:focus {
  color: #1F72CF; }

.header {
  position: relative;
  z-index: 100;
  height: 92px;
  font-size: 0; }

.header-top {
  padding: 6px 0;
  line-height: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 77, 163, 0.1); }

.header-top__name {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  color: #a9b0bd;
  font-weight: 500;
  letter-spacing: 0.08px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 25px; }

.header__region {
  display: inline-block;
  vertical-align: middle; }

.region {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 500; }

.region__link {
  border-bottom-color: rgba(0, 77, 163, 0.4);
  border-bottom-style: dotted;
  display: inline-block;
  line-height: 1.2; }

.region__heading {
  margin: 0;
  display: inline-block;
  color: #a9b0bd;
  margin-right: 3px; }

.header__time {
  display: inline-block;
  vertical-align: middle;
  padding-left: 19px;
  position: relative;
  color: #a9b0bd;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500; }
  .header__time:before {
    content: "";
    position: absolute;
    width: 14px;
    height: 14px;
    background: url(../img/clock.svg) no-repeat 0 0;
    background-size: contain;
    left: 0;
    top: 50%;
    margin-top: -7px; }

.header-bottom {
  position: absolute;
  z-index: 100;
  line-height: 1;
  width: 100%;
  left: 0;
  top: 31px;
  padding: 11px 0; }
  .header-bottom .container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .header-bottom .container:before, .header-bottom .container:after {
      display: none; }
  .header-bottom.is-fixed {
    position: fixed;
    top: 0;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    background: #fff; }

.logo {
  display: inline-block;
  border: 0; }

.header__logo {
  margin-right: 40px;
  display: inline-block;
  vertical-align: middle; }

.nav {
  display: inline-block;
  vertical-align: middle; }

.nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 0;
  white-space: nowrap; }

.nav-item {
  display: inline-block;
  vertical-align: top;
  margin-right: 25px;
  padding: 0; }
  .nav-item:last-child {
    margin-right: 0; }
  .nav-item:before {
    display: none; }
  .nav-item.is-active .nav-link {
    color: #2cbf4f; }
    .nav-item.is-active .nav-link svg {
      fill: #2cbf4f; }

.nav-item--lk {
  margin-left: 10px; }

.nav-link {
  display: inline-block;
  border: 0;
  font-size: 11px;
  letter-spacing: 0.06px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative; }
  .nav-link:hover {
    color: #003673; }

.nav-link--lk {
  padding-left: 30px; }
  .nav-link--lk svg {
    position: absolute;
    width: 22px;
    height: 22px;
    fill: #1F60A8;
    left: 0;
    top: 50%;
    margin-top: -12px;
    transition: all 0.3s; }
    .nav-link--lk svg use {
      fill: inherit; }
  .nav-link--lk:hover svg {
    fill: #003673; }

.header__contacts {
  text-align: right;
  line-height: 1;
  padding-bottom: 4px; }

.header__phone {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #1E1F21;
  letter-spacing: 0.1px;
  text-align: right;
  display: inline-block;
  margin-bottom: 2px;
  border: 0; }

.header__help {
  font-weight: 600;
  font-size: 11px;
  color: #004DA3;
  letter-spacing: 0.06px;
  border-bottom: 1px dashed rgba(0, 77, 163, 0.4);
  text-transform: uppercase; }

.menu-toggle {
  width: 18px;
  height: 12px;
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  outline: none;
  display: none;
  vertical-align: middle;
  margin-right: 20px; }
  .menu-toggle span {
    display: block;
    border-radius: 3px;
    height: 2px;
    margin-bottom: 3px;
    background: #004DA3;
    transition: all 0.3s;
    transform-origin: 0 0; }
    .menu-toggle span:last-child {
      margin-bottom: 0; }
  .menu-toggle svg {
    width: 18px;
    height: 14px;
    opacity: 0;
    position: absolute;
    top: -1px;
    left: 0;
    transition: all 0.3s; }
    .menu-toggle svg rect {
      fill: inherit; }
    .menu-toggle svg use {
      transition: all 0.3s;
      fill: #004DA3; }
  .menu-toggle:hover span {
    background: #2cbf4f; }
  .menu-toggle:hover svg use {
    fill: #2cbf4f; }
  .menu-toggle:focus span {
    background: #004DA3; }
  .menu-toggle:focus svg use {
    fill: #004DA3; }
  .menu-toggle:focus:hover span {
    background: #2cbf4f; }
  .menu-toggle:focus:hover svg use {
    fill: #2cbf4f; }
  .menu-toggle.is-active span:nth-child(1) {
    transform: rotate(45deg) translateX(1px) translateY(-2px); }
  .menu-toggle.is-active span:nth-child(2) {
    opacity: 0; }
  .menu-toggle.is-active span:nth-child(3) {
    transform: rotate(-45deg) translateX(-1px) translateY(2px); }

.mobile-menu-container {
  position: relative;
  display: none;
  vertical-align: middle;
  height: 40px; }

.mobile-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 20px;
  background: #FFFFFF;
  box-shadow: 0 9px 10px 0 rgba(0, 0, 0, 0.08);
  overflow: auto;
  min-width: 157px;
  padding: 11px 0; }

.nav--mobile {
  margin-right: 0;
  text-align: right;
  display: block;
  width: 100%; }
  .nav--mobile .nav-item {
    display: block;
    margin-right: 0; }
  .nav--mobile .nav-link {
    display: block;
    padding: 7px 12px;
    font-size: 11px; }
    .nav--mobile .nav-link:hover {
      background: #f1f6fa; }
  .nav--mobile .nav-item--child .nav-link {
    text-transform: none;
    margin-left: 12px; }

.header__circle-animate {
  display: none;
  position: fixed;
  z-index: -1;
  left: 0;
  height: 100%;
  width: 100%;
  top: 0; }
  .header__circle-animate circle {
    position: absolute;
    right: 0;
    top: 0; }

.mobile-menu__bottom {
  display: none;
  padding: 20px; }
  .mobile-menu__bottom .header__time {
    font-size: 14px;
    padding-left: 19px;
    line-height: 18px;
    letter-spacing: 0.08px;
    margin-bottom: 5px;
    display: inline-block; }
    .mobile-menu__bottom .header__time:before {
      width: 14px;
      height: 14px;
      margin-top: -7px; }
  .mobile-menu__bottom .header__contacts {
    display: block;
    margin-bottom: 30px;
    text-align: left; }
  .mobile-menu__bottom .nav-item--lk {
    display: block;
    margin-bottom: 15px; }
  .mobile-menu__bottom .nav-link--lk {
    padding-left: 30px;
    width: auto;
    height: auto;
    font-size: 11px;
    color: #1F60A8;
    position: relative;
    display: inline-block;
    margin-bottom: 18px; }
  .mobile-menu__bottom .header__phone {
    font-size: 26px;
    line-height: 32px;
    color: #1E1F21;
    letter-spacing: 0.15px;
    margin-bottom: 8px;
    display: inline-block; }
  .mobile-menu__bottom .header__help {
    font-size: 11px;
    letter-spacing: 0.06px; }
  .mobile-menu__bottom .region .dropdown__menu {
    top: auto;
    bottom: calc(100% + 8px); }

@media (max-width: 1245px) {
  .header {
    height: 93px; }
  .header-bottom .container {
    justify-content: flex-end;
    position: relative; }
  .mobile-menu-container {
    display: inline-block; }
  .menu-toggle {
    display: inline-block; }
  .header__logo {
    position: absolute;
    left: 20px;
    top: 4px; }
  .nav--desktop {
    margin-right: 40px; }
    .nav--desktop .nav-item {
      display: none; }
      .nav--desktop .nav-item:last-child {
        display: block; }
  .header__contacts {
    display: inline-block;
    vertical-align: middle; }
  .nav--mobile .nav-item:last-child {
    display: none; } }

@media (max-width: 945px) {
  .header-top__name {
    margin-right: 20px; } }

@media (max-width: 745px) {
  .header {
    height: 73px; }
  .mobile-menu-container {
    display: none; }
  .mobile-menu {
    position: fixed;
    top: 42px;
    left: 0;
    bottom: 0;
    background: none;
    display: flex !important;
    opacity: 0;
    visibility: hidden;
    padding-top: 0;
    padding-bottom: 0;
    right: 0;
    padding-top: 31px; }
    .mobile-menu.is-open {
      opacity: 1;
      visibility: visible; }
  .mobile-menu__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 42px;
    padding-bottom: 0px;
    width: 100%;
    height: auto; }
  .nav--mobile {
    white-space: normal;
    text-align: left; }
    .nav--mobile .nav-list {
      display: inline-block; }
    .nav--mobile .nav-item {
      display: block;
      margin: 0;
      margin-bottom: 14px;
      opacity: 0;
      transition: all 0.5s;
      margin-bottom: 18px;
      padding-left: 20px; }
      .nav--mobile .nav-item:nth-child(n) {
        display: block; }
    .nav--mobile .nav-link {
      display: inline-block;
      font-weight: 600;
      padding: 0;
      font-size: 14px;
      letter-spacing: 0.48px;
      font-weight: 600; }
      .nav--mobile .nav-link svg {
        left: 0;
        margin-left: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-top: -1px; }
      .nav--mobile .nav-link:hover {
        background: none; }
  .header__circle-animate {
    display: block; }
  .mobile-menu__bottom {
    transition: all 0.4s;
    opacity: 0; }
  .menu-opened .nav--mobile .nav-item {
    opacity: 1; }
    .menu-opened .nav--mobile .nav-item:nth-child(1) {
      transition-delay: 0.2s; }
    .menu-opened .nav--mobile .nav-item:nth-child(2) {
      transition-delay: 0.3s; }
    .menu-opened .nav--mobile .nav-item:nth-child(3) {
      transition-delay: 0.4s; }
    .menu-opened .nav--mobile .nav-item:nth-child(4) {
      transition-delay: 0.5s; }
    .menu-opened .nav--mobile .nav-item:nth-child(5) {
      transition-delay: 0.6s; }
    .menu-opened .nav--mobile .nav-item:nth-child(6) {
      transition-delay: 0.7s; }
    .menu-opened .nav--mobile .nav-item:nth-child(7) {
      transition-delay: 0.7s; }
    .menu-opened .nav--mobile .nav-item:nth-child(8) {
      transition-delay: 0.7s; }
  .menu-opened .mobile-menu__bottom {
    opacity: 1;
    transition: all 1s;
    transition-delay: 0.8s; }
  .menu-opened .header-bottom__right .header__contacts {
    opacity: 0;
    visibility: hidden; }
  .menu-opened .nav--desktop .nav-item--lk {
    opacity: 0;
    visibility: hidden; }
  .menu-opened .header-top {
    opacity: 0;
    visibility: hidden; }
  .header__region {
    display: block;
    font-size: 12px; }
  .header-top__name {
    display: none; }
  .header__phone {
    font-size: 11px;
    letter-spacing: 0.06px;
    text-align: right;
    margin-bottom: 1px; }
  .header__help {
    font-size: 9px;
    letter-spacing: 0.05px; }
  .menu-toggle {
    position: absolute;
    right: 0px;
    top: 50%;
    margin-top: -6px; }
  .header__time {
    font-size: 12px;
    letter-spacing: 0.07px;
    text-align: right;
    padding-left: 13px; }
    .header__time:before {
      width: 10px;
      height: 10px;
      margin-top: -5px; }
  .nav-item--lk {
    position: absolute;
    right: 50px;
    top: 50%;
    margin-top: -11px; }
  .nav-link--lk {
    font-size: 0;
    padding: 0;
    width: 22px;
    height: 22px; }
  .header__logo {
    width: 94px; }
    .header__logo svg {
      width: 94px; }
  .header-bottom {
    padding: 2px 0;
    padding-bottom: 4px; }
    .header-bottom .container {
      padding-right: 88px;
      z-index: 1; }
    .header-bottom.is-fixed .mobile-menu {
      height: calc(100vh - 42px);
      padding-top: 0; }
  .header.menu-opened .header__get-help {
    border-radius: 50%; }
    .header.menu-opened .header__get-help.border-radius0 {
      border-radius: 50%; }
      .header.menu-opened .header__get-help.border-radius0:before, .header.menu-opened .header__get-help.border-radius0:after {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        transform: translateY(0) translateX(0); }
  .mobile-menu__bottom {
    display: block;
    width: 100%; } }

.hero {
  padding-top: 56px;
  padding-bottom: 60px; }
  .hero .container {
    display: flex;
    flex-wrap: wrap;
    position: relative; }

.hero--bg {
  position: relative;
  top: calc(-92px);
  margin: 0 0 calc(-92px);
  overflow: hidden;
  border-bottom: 1px solid #E2E5EA;
  padding: calc(56px + 92px) 0 0; }
  .hero--bg .container {
    position: static; }
    .hero--bg .container:after {
      display: none; }
    .hero--bg .container:before {
      display: none; }

.hero__left {
  width: 50%; }

.hero__right {
  width: 50%; }

.hero__row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px; }

.hero__col {
  position: relative;
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 10px 150px; }
  @media (max-width: 1245px) {
    .hero__col.hero__col-left {
      flex: 1;
      max-width: none; }
    .hero__col.hero__col-right {
      flex: 1;
      max-width: 380px; } }
  @media (max-width: 945px) {
    .hero__col.hero__col-left {
      flex: 1;
      max-width: none; }
    .hero__col.hero__col-right {
      flex: 0 0 100%;
      max-width: none; } }

.hero__price {
  background: #00C767;
  box-shadow: 0px 0px 12px rgba(165, 191, 199, 0.115517), 0px 14px 15px rgba(168, 186, 199, 0.106573);
  border-radius: 14px;
  display: inline-block;
  vertical-align: top;
  padding: 12px 100px 27px 20px;
  color: #fff;
  margin: 0 0 40px;
  position: relative;
  z-index: 1;
  font-size: 28px;
  line-height: 38px;
  font-weight: 600; }
  .hero__price small {
    display: block;
    font-size: 16px;
    line-height: 20px;
    font-weight: 450;
    padding: 10px 0 0; }
  .hero__price svg {
    width: 66px;
    height: 66px;
    position: absolute;
    top: 50%;
    right: 22px;
    margin: -33px 0 0;
    z-index: -1; }
  @media (max-width: 745px) {
    .hero__price {
      font-size: 24px;
      line-height: 34px; } }

.hero__list {
  margin: 0;
  padding: 0;
  list-style: none;
  max-width: 520px;
  font-size: 18px;
  line-height: 25px; }
  .hero__list > li {
    position: relative;
    padding: 0 0 0 42px;
    margin: 0 0 29px; }
    .hero__list > li:last-of-type {
      margin: 0; }
    .hero__list > li:before {
      display: none; }
  .hero__list svg {
    display: block;
    width: 22px;
    height: 22px;
    position: absolute;
    top: 0;
    left: 0; }
  @media (max-width: 945px) {
    .hero__list {
      font-size: 16px;
      line-height: 22px; } }

.hero__decor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.hero__decor-bg {
  width: 1334px;
  height: 1328px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  margin: -664px 0 0 -667px; }
  .hero__decor-bg--01 {
    background: #fff url(../img/hero_bg-01.jpg) center bottom no-repeat; }
  .hero__decor-bg--02 {
    background: #fff url(../img/hero_bg-02.jpg) center bottom no-repeat; }

.hero__decor-item {
  position: absolute;
  pointer-events: none; }
  .hero__decor-item--ball {
    top: -20px;
    left: -40px;
    width: 144px;
    height: 108px;
    background: url(../img/hero_decor-ball.png) 0 0 no-repeat;
    z-index: -1; }
  .hero__decor-item--umbrella {
    top: -80px;
    left: 50%;
    width: 712px;
    height: 717px;
    background: url(../img/hero_decor-umbrella.png) 0 0 no-repeat;
    z-index: -1;
    margin: 0 0 0 -100px; }
  .hero__decor-item--bailer {
    bottom: 0;
    left: 50%;
    width: 333px;
    height: 324px;
    background: url(../img/hero_decor-bailer.png) 0 0 no-repeat;
    z-index: 3;
    margin: 0 0 -110px -400px; }
  .hero__decor-item--bird {
    top: -97px;
    left: 50%;
    width: 234px;
    height: 194px;
    z-index: -1;
    margin: 0 0 0 -384px;
    background: url(../img/hero_decor-bird.png) 0 0 no-repeat; }
  .hero__decor-item--butterfly_01 {
    top: 50%;
    left: 50%;
    width: 64px;
    height: 56px;
    z-index: -1;
    margin: -64px 0 0 -324px;
    background: url(../img/hero_decor-butterfly-01.png) 0 0 no-repeat; }
  .hero__decor-item--butterfly_02 {
    top: 50%;
    left: 50%;
    width: 79px;
    height: 72px;
    z-index: 2;
    margin: -30px 0 0 125px;
    background: url(../img/hero_decor-butterfly-02.png) 0 0 no-repeat; }
  .hero__decor-item--bush {
    bottom: 0;
    left: 50%;
    width: 582px;
    height: 217px;
    z-index: 2;
    margin: 40px 0 0 -120px;
    background: url(../img/hero_decor-bush.png) 0 0 no-repeat; }
  @media (max-width: 945px) {
    .hero__decor-item--01 {
      top: -154px;
      left: 10%;
      margin: 0; } }

.support-webp .hero__decor-item--ball {
  background: url(../img/hero_decor-ball.webp) 0 0 no-repeat; }

.support-webp .hero__decor-item--umbrella {
  background: url(../img/hero_decor-umbrella.webp) 0 0 no-repeat; }

.support-webp .hero__decor-item--bailer {
  background: url(../img/hero_decor-bailer.webp) 0 0 no-repeat; }

.support-webp .hero__decor-item--bird {
  background: url(../img/hero_decor-bird.webp) 0 0 no-repeat; }

.support-webp .hero__decor-item--butterfly_01 {
  background: url(../img/hero_decor-butterfly-01.webp) 0 0 no-repeat; }

.support-webp .hero__decor-item--butterfly_02 {
  background: url(../img/hero_decor-butterfly-02.webp) 0 0 no-repeat; }

.support-webp .hero__decor-item--bush {
  background: url(../img/hero_decor-bush.webp) 0 0 no-repeat; }

.hero__form {
  background: #FFFFFF;
  box-shadow: 0px 0px 12px rgba(165, 191, 199, 0.115517), 0px 14px 15px rgba(168, 186, 199, 0.106573);
  border-radius: 14px;
  max-width: 380px;
  margin: 0 auto;
  padding: 28px 50px 60px;
  position: relative;
  z-index: 1; }
  @media (max-width: 945px) {
    .hero__form {
      max-width: none;
      margin-bottom: 67px; } }
  @media (max-width: 575px) {
    .hero__form {
      padding: 28px 30px 60px; } }

.hero__form-heading {
  display: block;
  text-align: center;
  font-size: 28px;
  line-height: 30px;
  font-weight: 600;
  padding: 10px 0;
  margin: 0; }
  @media (max-width: 945px) {
    .hero__form-heading {
      font-size: 22px;
      line-height: 26px; } }

.hero__form-text {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  font-weight: 450;
  margin: 0 0 26px; }
  @media (max-width: 945px) {
    .hero__form-text {
      font-size: 14px;
      line-height: 18px; } }

.hero__illustration {
  width: 1211px;
  height: 729px;
  position: absolute;
  background-image: url("../img/hero_illustration.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  top: -30px;
  left: calc(50% - 90px);
  z-index: -1; }
  .hero__illustration.webp {
    background-image: url("../img/hero_illustration.webp"); }

.b-breadcrumb {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.0816667px;
  padding: 20px 0;
  color: #9BA5B1;
  margin: 0 0 -20px; }
  .b-breadcrumb .bx-breadcrumb {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -5px -10px;
    cursor: default; }
  .b-breadcrumb .bx-breadcrumb-item {
    margin: 0;
    padding: 0 5px 10px; }
    .b-breadcrumb .bx-breadcrumb-item a {
      border: none; }
  .b-breadcrumb svg {
    width: 8px;
    height: 8px;
    margin-right: 4px;
    margin-top: 0px; }
  .b-breadcrumb:first-child {
    border-top: 1px solid #E2E5EA;
    margin-bottom: 12px; }
  @media (min-width: 1200px) {
    .b-breadcrumb {
      margin: 0 0 40px; } }

.offer {
  margin-bottom: 25px; }
  .offer h1 {
    font-weight: 600;
    font-size: 39px;
    letter-spacing: 0.23px;
    line-height: 50px;
    margin-bottom: 15px; }
    .offer h1 span {
      color: #1F72CF; }
  .offer > span {
    font-weight: 600;
    font-size: 20px;
    color: #3C3C3C;
    letter-spacing: 0.12px;
    line-height: 19px;
    display: block; }
    .offer > span span {
      color: #1F72CF; }

.benefits {
  margin-bottom: 41px;
  font-size: 0; }

.benefit {
  position: relative;
  padding-left: 28px;
  font-weight: 500;
  font-size: 14px;
  color: #3C3C3C;
  letter-spacing: 0.08px;
  line-height: 21px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 46px; }
  .benefit:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%; }
  .benefit--installation:before {
    width: 16px;
    height: 16px;
    background: url(../img/benefit_icon_clock.svg) no-repeat 0 0;
    background-size: contain;
    margin-top: -8px; }
  .benefit--testing {
    margin-right: 0; }
    .benefit--testing:before {
      width: 18px;
      height: 18px;
      background: url(../img/benefit_icon_schedule.svg) no-repeat 0 0;
      background-size: contain;
      margin-top: -10px; }

.benefit__tooltip {
  margin-top: -5px; }

.prices {
  max-width: 580px;
  background: #FFFFFF;
  box-shadow: 0 14px 15px 0 rgba(168, 186, 199, 0.11), 0 0 12px 0 rgba(165, 191, 199, 0.12);
  border-radius: 14px;
  margin-bottom: 60px;
  display: flex;
  padding: 20px; }

.price-wrap {
  width: 50%;
  border-right: 1px solid #E1E7EA;
  padding-bottom: 3px; }
  .price-wrap:last-child {
    border-right: 0; }
  .price-wrap p {
    font-weight: 500;
    margin: 0;
    margin-bottom: 12px; }

.price {
  font-weight: 600;
  font-size: 28px;
  letter-spacing: 0.16px;
  line-height: 1;
  margin-bottom: 4px;
  min-height: 28px;
  display: flex;
  align-items: flex-end; }

.price-wrap__icons {
  font-size: 0; }
  .price-wrap__icons svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px; }
    .price-wrap__icons svg:last-child {
      margin-right: 0; }

.price-wrap--right {
  padding-left: 20px; }
  .price-wrap--right .price {
    font-size: 18px; }

.price-wrap__sim {
  position: relative;
  padding-left: 24px;
  font-size: 13px;
  letter-spacing: 0.08px;
  line-height: 16px; }
  .price-wrap__sim:before {
    content: "";
    position: absolute;
    width: 14px;
    height: 17px;
    background: url(../img/sim.svg) no-repeat 0 0;
    background-size: contain;
    left: 0;
    top: 3px; }

.tarif-tooltip-link {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-weight: 500;
  color: #00C767;
  border-bottom: 1px dashed #00C767;
  display: inline-block;
  line-height: 1;
  z-index: 10; }
  .tarif-tooltip-link:hover .tarif-tooltip {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px); }

.tarif-tooltip {
  background: rgba(0, 0, 0, 0.77);
  border-radius: 5px;
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.14);
  width: 162px;
  position: absolute;
  left: -16px;
  top: 100%;
  padding: 20px 16px;
  margin-top: 16px;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-7px);
  cursor: default; }
  .tarif-tooltip:after {
    content: "";
    position: absolute;
    height: 16px;
    left: 0;
    width: 100%;
    top: -16px; }
  .tarif-tooltip:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 6px solid rgba(0, 0, 0, 0.77);
    border-top: 6px solid rgba(0, 0, 0, 0);
    border-left: 8px solid rgba(0, 0, 0, 0);
    border-right: 8px solid rgba(0, 0, 0, 0);
    top: -12px;
    left: 36px; }

.tooltip-operators {
  padding: 0;
  margin: 0; }
  .tooltip-operators li:before {
    display: none; }

.tooltip-operator {
  font-weight: 500;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 0.08px;
  line-height: 16px;
  padding-left: 32px;
  position: relative;
  margin-bottom: 15px; }
  .tooltip-operator:last-child {
    margin: 0; }
  .tooltip-operator svg {
    position: absolute;
    top: 50%;
    left: 0px; }
  .tooltip-operator--megafon svg {
    width: 21px;
    height: 21px;
    margin-top: -10px; }
  .tooltip-operator--mts svg {
    width: 19px;
    height: 19px;
    left: 1px;
    margin-top: -9px; }
  .tooltip-operator--tele2 svg {
    width: 14px;
    height: 17px;
    left: 3px;
    margin-top: -9px; }
  .tooltip-operator--beeline svg {
    width: 20px;
    height: 20px;
    margin-top: -10px; }
  .tooltip-operator--rostelecom svg {
    width: 20px;
    height: 20px;
    margin-top: -10px; }

.tooltip-info {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url(../img/round_info.svg) no-repeat 0 0;
  background-size: contain;
  z-index: 10;
  cursor: pointer; }

.tippy-tooltip {
  font-weight: 600;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 0.08px;
  text-align: right;
  line-height: 21px;
  padding: 5px 10px;
  background: rgba(0, 0, 0, 0.77);
  border-radius: 5px;
  cursor: default;
  text-align: left; }
  .tippy-tooltip:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 6px solid rgba(0, 0, 0, 0.77);
    border-bottom: 6px solid rgba(0, 0, 0, 0);
    border-left: 8px solid rgba(0, 0, 0, 0);
    border-right: 8px solid rgba(0, 0, 0, 0);
    bottom: -12px;
    left: 14px; }
  @media (max-width: 745px) {
    .tippy-tooltip:before {
      display: none; } }

.find-out {
  max-width: 537px; }

.find-out__form {
  margin-bottom: 9px; }
  .find-out__form .form-group {
    margin: 0; }

.find-out__heading {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.12px;
  line-height: 26px; }

.find-out__suggestions {
  font-size: 0; }
  .find-out__suggestions p {
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    font-size: 14px;
    color: #9BA5B1;
    letter-spacing: 0.08px;
    line-height: 22px; }
  .find-out__suggestions a {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    color: #004DA3;
    letter-spacing: 0.08px;
    line-height: 1.2;
    margin-right: 10px;
    border-bottom-style: dashed;
    border-bottom-color: rgba(0, 77, 163, 0.4); }

.find-out-result__heading h4, .find-out-result__heading .success_title {
  font-weight: bold;
  font-size: 20px;
  color: #0ABE92;
  letter-spacing: 0.12px;
  line-height: 26px;
  margin-bottom: 15px; }

.find-out-result__heading p {
  font-size: 14px;
  color: #3C3C3C;
  letter-spacing: 0.08px;
  line-height: 19px;
  margin-bottom: 5px; }

.find-out-result__another-city {
  font-size: 14px;
  letter-spacing: 0.08px;
  line-height: 1.25;
  border-bottom: 1px dashed rgba(0, 77, 163, 0.4);
  display: inline-block;
  margin-bottom: 20px; }

.find-out-result {
  max-width: 580px;
  display: none; }

.find-out-result__form {
  text-align: right; }
  .find-out-result__form .form-group {
    text-align: left; }
  .find-out-result__form .form-field-container {
    margin-bottom: 20px; }
  .find-out-result__form .form__button {
    display: inline-block;
    vertical-align: middle;
    padding: 0; }
    .find-out-result__form .form__button .btn {
      width: 122px; }
  .find-out-result__form .form__note {
    color: #9c9c9c;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    padding-right: 12px;
    max-width: 290px; }
    .find-out-result__form .form__note a {
      color: #9c9c9c;
      border-bottom-color: rgba(190, 190, 190, 0.3); }
      .find-out-result__form .form__note a:hover {
        color: #000;
        border-bottom-color: rgba(190, 190, 190, 0.6); }
  .find-out-result__form.is-submitted .form__note {
    display: none; }
  .find-out-result__form.is-submitted .btn {
    width: 181px;
    color: rgba(255, 255, 255, 0); }
  .find-out-result__form.is-submitted .btn__success {
    display: flex; }

@media (max-width: 1245px) {
  .offer h1 {
    font-size: 32px;
    letter-spacing: 0.19px;
    line-height: 40px; }
  .hero__left {
    width: 56%; }
  .hero__illustration {
    top: 50px;
    left: calc(50% - 195px); }
  .find-out {
    max-width: 475px; }
  .hero__illustration {
    width: 999px;
    height: 595px; } }

@media (min-width: 946px) and (max-width: 1245px) {
  .prices {
    max-width: 300px;
    display: block;
    margin-bottom: 68px; }
  .price-wrap {
    width: 100%; }
  .price-wrap--left {
    border: 0;
    border-bottom: 1px solid #E1E7EA;
    padding-bottom: 29px; }
  .price-wrap--right {
    padding-left: 0;
    padding-top: 20px; } }

@media (max-width: 945px) {
  .hero__left {
    width: 100%;
    max-width: 500px; }
  .hero__illustration {
    position: relative;
    left: -100px;
    top: -80px;
    margin-bottom: -213px; } }

@media (max-width: 745px) {
  .offer {
    margin-bottom: 30px; }
    .offer h1 {
      font-size: 22px;
      letter-spacing: 0.13px;
      line-height: 28px;
      margin-bottom: 20px; }
      .offer h1 span {
        display: block; }
    .offer > span {
      font-size: 16px;
      letter-spacing: 0.09px;
      line-height: 19px; }
  .benefit {
    display: block;
    margin-right: 0;
    margin-bottom: 9px; }
  .hero__illustration {
    width: 711px;
    height: 423px;
    left: 50%;
    margin-left: -250px;
    margin-bottom: -156px; }
  .prices {
    max-width: 300px;
    display: block;
    margin-bottom: 33px; }
  .price-wrap {
    width: 100%; }
  .price-wrap--left {
    border: 0;
    border-bottom: 1px solid #E1E7EA;
    padding-bottom: 29px; }
  .price-wrap--right {
    padding-left: 0;
    padding-top: 20px; }
  .find-out__heading {
    font-size: 16px;
    letter-spacing: 0.09px;
    line-height: 26px;
    margin-bottom: 14px; }
  .find-out .form-placeholder {
    font-size: 0; }
    .find-out .form-placeholder span {
      font-size: 14px;
      display: block; }
  .find-out__suggestions a {
    display: none; }
    .find-out__suggestions a:nth-child(2) {
      display: inline-block; }
    .find-out__suggestions a:nth-child(3) {
      display: inline-block; } }

@media (max-width: 510px) {
  .find-out-result__form .form__button {
    margin-bottom: 10px; }
  .find-out-result__form.is-submitted .form__note {
    display: block; }
  .find-out-result__form-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end; }
    .find-out-result__form-footer .form__button {
      order: 1;
      width: 100%;
      text-align: right; }
    .find-out-result__form-footer .form__note {
      order: 2;
      margin: 0;
      padding-right: 0; } }

.b-guarantee .container {
  display: flex;
  flex-wrap: wrap; }

.b-guarantee__left {
  width: 53.4%;
  padding-top: 117px; }

.b-guarantee__right {
  width: 46.6%;
  padding-top: 77px;
  padding-left: 52px; }
  .b-guarantee__right .section-title {
    padding-top: 0;
    margin-bottom: 49px;
    font-weight: 300;
    font-size: 39px;
    letter-spacing: 0.23px;
    line-height: 44px; }
    .b-guarantee__right .section-title b {
      display: block;
      font-weight: bold; }

.kits-image {
  position: relative; }

.kits-image__dot {
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  z-index: 1;
  cursor: pointer; }
  .kits-image__dot:after {
    content: "";
    position: absolute;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: rgba(28, 219, 91, 0.39);
    top: 50%;
    left: 50%;
    margin-left: -28px;
    margin-top: -28px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05); }
  .kits-image__dot:before {
    content: "";
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #1CDB5B;
    left: 0;
    top: 0;
    transition: all 0.3s; }
  .kits-image__dot:hover {
    z-index: 2; }
    .kits-image__dot:hover:before {
      transform: scale(1.4); }
    .kits-image__dot:hover .kits-image__dot-content {
      width: 196px;
      height: 96px;
      border-radius: 5px;
      left: 50%;
      bottom: 50%; }
      .kits-image__dot:hover .kits-image__dot-content p {
        transition-delay: 0.25s;
        opacity: 1; }
  .kits-image__dot:nth-child(1) {
    left: -6px;
    top: 116px; }
    .kits-image__dot:nth-child(1) .kits-image__dot-icon {
      width: 12px;
      height: 18px;
      background-image: url(../img/kit_icon1.svg); }
  .kits-image__dot:nth-child(2) {
    left: 192px;
    top: 70px; }
    .kits-image__dot:nth-child(2) .kits-image__dot-icon {
      width: 15px;
      height: 19px;
      background-image: url(../img/kit_icon2.svg); }
  .kits-image__dot:nth-child(3) {
    left: 201px;
    top: 183px; }
    .kits-image__dot:nth-child(3) .kits-image__dot-icon {
      width: 14px;
      height: 14px;
      background-image: url(../img/kit_icon3.svg); }
  .kits-image__dot:nth-child(4) {
    right: 79px;
    bottom: 39px; }
    .kits-image__dot:nth-child(4) .kits-image__dot-icon {
      width: 16px;
      height: 13px;
      background-image: url(../img/kit_icon5.svg); }
  .kits-image__dot:nth-child(5) {
    right: -16px;
    top: 193px; }
    .kits-image__dot:nth-child(5) .kits-image__dot-icon {
      width: 16px;
      height: 16px;
      background-image: url(../img/kit_icon6.svg); }

.kits-image__dot-content {
  background: #33c33d;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  left: 0;
  bottom: 0;
  z-index: -1;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  position: absolute;
  overflow: hidden;
  transition: all 0.3s;
  transition-timing-function: ease-in-out; }
  .kits-image__dot-content p {
    transition: all 0.3s;
    padding: 16px 20px;
    margin: 0;
    opacity: 0; }
  .kits-image__dot-content svg {
    display: none; }

.kits-image__dot-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
  backface-visibility: hidden; }

.section-title {
  padding-top: 60px;
  font-weight: bold;
  font-size: 26px;
  letter-spacing: 0.15px;
  line-height: 1.128;
  margin-bottom: 30px; }
  .section-title--left {
    text-align: left; }
  .section-title--center {
    text-align: center; }
  .section-title--lg {
    font-weight: 600;
    font-size: 39px;
    letter-spacing: 0.23px;
    line-height: 42px; }

.guarantee-features {
  max-width: 500px;
  margin-bottom: 20px; }

.guarantee-features__item {
  margin-bottom: 30px;
  position: relative;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.1px;
  padding-left: 35px; }
  .guarantee-features__item:last-child {
    margin-bottom: 0; }
  .guarantee-features__item:before {
    content: "";
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    left: 0;
    top: -1px; }
  .guarantee-features__item h3 {
    display: block;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.1px;
    font-weight: bold;
    margin: 0; }
  .guarantee-features__item--item1:before {
    background-image: url(../img/guarantee_feature_icon1.svg);
    width: 22px;
    height: 22px; }
  .guarantee-features__item--item2:before {
    background-image: url(../img/guarantee_feature_icon2.svg);
    width: 22px;
    height: 21px;
    top: 1px; }
  .guarantee-features__item--item3:before {
    background-image: url(../img/guarantee_feature_icon3.svg);
    width: 22px;
    height: 23px; }

.b-guarantee__lk-button {
  font-size: 0;
  line-height: 1;
  padding-left: 35px; }

@media (max-width: 1245px) {
  .b-guarantee__left {
    width: 100%;
    padding-top: 35px;
    padding-bottom: 30px; }
  .b-guarantee__right {
    width: 100%;
    padding: 0; }
  .kits-image {
    max-width: 640px;
    margin: 0 auto; }
  .kits-image__dot-icon {
    transform: translate(-50%, -50%) scale(0.82); }
  .b-guarantee__right .section-title b {
    display: inline-block; }
  .guarantee-features {
    display: flex;
    max-width: none;
    margin-right: -20px; }
  .guarantee-features__item {
    width: calc(33.33% - 20px);
    margin-right: 20px;
    padding-left: 0;
    padding-top: 32px; }
    .guarantee-features__item h3 {
      display: inline; }
    .guarantee-features__item:before {
      left: 0;
      top: 0; }
  .b-guarantee__lk-button {
    padding-left: 0;
    width: calc(33.33% - 14px);
    float: right; } }

@media (max-width: 945px) {
  .b-guarantee__right .section-title {
    text-align: center;
    margin-bottom: 60px; }
    .b-guarantee__right .section-title b {
      display: block; }
  .guarantee-features {
    margin-right: 0px;
    justify-content: space-around;
    flex-wrap: wrap; }
  .guarantee-features__item {
    width: 50%;
    max-width: 280px;
    margin-right: 0;
    text-align: center; }
    .guarantee-features__item:before {
      left: 50%;
      transform: translateX(-50%); }
  .b-guarantee__lk-button {
    width: 100%;
    text-align: center; } }

@media (max-width: 745px) {
  .b-guarantee__left {
    padding-top: 15px; }
  .kits-image__image {
    margin-bottom: 0; }
  .kits-image__dots {
    display: none; }
  .section-title {
    font-size: 24px;
    letter-spacing: 0.14px;
    line-height: 30px; }
    .section-title--lg {
      font-size: 28px;
      letter-spacing: 0.16px;
      line-height: 32px; }
  .b-guarantee__right .section-title {
    font-size: 24px;
    letter-spacing: 0.14px;
    text-align: center;
    line-height: 30px;
    margin-bottom: 48px;
    padding-bottom: 0; }
  .guarantee-features {
    display: block; }
  .guarantee-features__item {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 40px;
    font-size: 14px;
    letter-spacing: 0.08px;
    text-align: center;
    line-height: 21px; }
    .guarantee-features__item h3 {
      display: block;
      font-size: 14px;
      letter-spacing: 0.08px;
      line-height: 21px; } }

.your-kit {
  padding-top: 120px;
  padding-bottom: 100px; }
  .your-kit .container {
    display: flex;
    flex-wrap: wrap; }

.your-kit__left {
  width: 66.2%; }

.your-kit__right {
  padding-left: 37px;
  width: 33.8%; }

.your-kit__heading {
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 39px;
  color: #1E1F21;
  letter-spacing: 0.23px;
  line-height: 50px;
  width: 100%; }
  .your-kit__heading img {
    display: inline-block;
    position: relative;
    top: -2px; }

.kit-constructor {
  display: flex;
  flex-wrap: wrap; }

.kit-constructor__title {
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0.08px;
  line-height: 21px;
  margin-bottom: 11px; }

.kit-constructor__subscription-fee {
  font-weight: 600;
  font-size: 28px;
  color: #FFFFFF;
  letter-spacing: 0.16px;
  line-height: 50px;
  margin-top: -17px;
  margin-bottom: 17px; }
  .kit-constructor__subscription-fee sup {
    opacity: 0.6;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.08px;
    line-height: 21px;
    position: relative;
    top: -12px; }

.constructor-item {
  background: #FFFFFF;
  transition: all 0.3s;
  width: calc(50% + 1px);
  border: 1px solid #E5EBED;
  min-height: 170px;
  padding: 19px 20px;
  padding-bottom: 4px;
  padding-left: 150px;
  position: relative;
  color: #1E1F21;
  cursor: pointer;
  margin-right: -1px;
  margin-bottom: -1px; }
  .constructor-item:hover {
    border-color: #00C767;
    box-shadow: inset 0 0 0 1px #00C767;
    z-index: 1; }
    .constructor-item:hover .constructor-item__info {
      fill: #2cbf4f; }
  .constructor-item--sm {
    width: calc(25% + 1px);
    padding: 19px 20px;
    padding-bottom: 14px;
    padding-top: 105px;
    text-align: center; }
    .constructor-item--sm .constructor-item__name {
      padding-right: 0; }
    .constructor-item--sm .constructor-item__price {
      margin-bottom: 0; }
    .constructor-item--sm .constructor-item__image {
      width: 100%;
      height: 105px;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 20px; }
      .constructor-item--sm .constructor-item__image div {
        padding: 0;
        position: relative;
        font-size: 0;
        line-height: 1; }
        .constructor-item--sm .constructor-item__image div img {
          max-height: 45px; }

.constructor-item__image {
  position: absolute;
  left: 0;
  top: 23px;
  height: 123px;
  width: 142px;
  text-align: center;
  font-size: 0;
  line-height: 1; }
  .constructor-item__image div {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    display: none;
    padding: 0 30px; }
    .constructor-item__image div img {
      max-height: 123px;
      width: auto; }
    .constructor-item__image div.is-active {
      display: block; }
    .constructor-item__image div.four-antenna {
      padding: 0 15px; }

.constructor-item__name {
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.12px;
  line-height: 21px;
  margin-bottom: 6px;
  padding-right: 20px; }

.constructor-item__price {
  font-weight: 500;
  font-size: 13px;
  color: #3C3C3C;
  letter-spacing: 0.08px;
  line-height: 21px;
  margin-bottom: 10px; }
  .constructor-item__price span {
    display: none; }
    .constructor-item__price span.is-active {
      display: block; }

.constructor-item__buttons {
  font-size: 0;
  margin-bottom: -10px;
  margin-right: -10px;
  padding-top: 5px; }

.constructor-item__button {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
  z-index: 2;
  user-select: none; }
  .constructor-item__button input {
    display: none; }
    .constructor-item__button input:checked ~ span {
      background: #2cbf4f;
      color: #fff; }
  .constructor-item__button span {
    padding: 4px 10px;
    display: block;
    background: #e2ecf1;
    border-radius: 5px;
    transition: all 0.3s;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11px;
    color: #7D98A5;
    letter-spacing: 0.46px;
    line-height: 21px; }
  .constructor-item__button:hover span {
    background: #d1e0e8; }

.constructor-item__buttons--router .constructor-item__button span:last-child {
  display: none; }

.constructor-item__popup-trigger {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1; }

.constructor-item__info {
  position: absolute;
  width: 20px;
  height: 20px;
  transition: all 0.3s;
  fill: #CCD1D9;
  top: 15px;
  right: 15px; }
  .constructor-item__info use {
    fill: inherit; }

.constructor-item__descr {
  opacity: 0.6;
  font-weight: 500;
  font-size: 13px;
  color: #3C3C3C;
  letter-spacing: 0.08px;
  line-height: 20px;
  margin-bottom: 6px;
  max-width: 200px; }

.constructor-item__operators {
  font-size: 0;
  line-height: 1; }
  .constructor-item__operators img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 9px; }

.constructor-item--sim .constructor-item__image div {
  padding-right: 0; }

.constructor-item--modem .constructor-item__image {
  text-align: center; }
  .constructor-item--modem .constructor-item__image div img {
    max-height: 89px; }

.kit-constructor__result {
  font-size: 0;
  margin-bottom: 39px; }

.kit-constructor__total {
  opacity: 0.9;
  font-weight: 600;
  font-size: 39px;
  letter-spacing: 0.23px;
  line-height: 50px; }

.kit-constructor__plus-week-testing {
  font-weight: 500;
  font-size: 14px;
  color: #798494;
  letter-spacing: 0.08px;
  line-height: 21px;
  margin-bottom: 27px;
  display: inline-block;
  cursor: pointer; }
  .kit-constructor__plus-week-testing span {
    border-bottom: 1px dotted #798494; }
  .kit-constructor__plus-week-testing .tippy-tooltip {
    margin-left: 20px;
    margin-bottom: -5px; }

.kit-constructor__discount {
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
  background: #FFDE20;
  border-radius: 100px;
  padding: 4px 10px;
  padding-top: 5px;
  font-weight: 600;
  font-size: 14px;
  color: #BD8500;
  letter-spacing: 0.08px;
  line-height: 21px; }
  .kit-constructor__discount span {
    display: inline-block;
    line-height: 1.1;
    border-bottom: 1px dotted #BD8500; }
  .kit-constructor__discount .tippy-tooltip {
    margin-left: 20px; }

.kit-constructor__old-price {
  display: inline-block;
  vertical-align: middle;
  opacity: 0.6;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.08px;
  line-height: 15px;
  text-decoration: line-through; }

.what-to-choose {
  padding: 19px 22px;
  padding-top: 16px;
  background: #F4F7F9;
  border-radius: 20px;
  position: relative;
  max-width: 282px;
  font-size: 0; }
  .what-to-choose h4, .what-to-choose .what-to-choose__title {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.12px;
    line-height: 22px;
    margin-bottom: 15px; }
  .what-to-choose .btn {
    margin-bottom: 18px; }
  .what-to-choose:before {
    content: "";
    position: absolute;
    width: 28px;
    height: 21px;
    left: 0;
    top: -8px;
    background: url(../img/bubble_path.svg) no-repeat 0 0;
    background-size: contain; }

.what-to-choose__phone p {
  margin: 0;
  margin-bottom: 6px;
  font-size: 14px;
  letter-spacing: 0.08px;
  line-height: 22px; }

.what-to-choose__phone a {
  font-weight: 600;
  font-size: 18px;
  color: #1F72CF;
  letter-spacing: 0.1px;
  text-align: right;
  border: 0; }
  .what-to-choose__phone a:hover {
    color: #1E1F21; }

@media (max-width: 1245px) {
  .your-kit__heading {
    text-align: center; }
  .your-kit__left {
    width: 100%;
    margin-bottom: 30px; }
  .your-kit__right {
    width: 100%;
    padding-left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end; }
  .kit-constructor__result {
    margin-bottom: 0; }
  .what-to-choose {
    margin-left: 25px; }
    .what-to-choose:before {
      width: 28px;
      height: 30px;
      background: url(../img/bubble_path2.svg) no-repeat 0 0;
      background-size: contain;
      top: auto;
      bottom: 0;
      left: -11px; } }

@media (max-width: 945px) {
  .your-kit {
    padding-top: 100px; }
  .constructor-item {
    padding-left: 120px; }
    .constructor-item--sm {
      padding-left: 20px; }
  .constructor-item__image {
    width: 120px; }
    .constructor-item__image div {
      padding: 0 20px; }
  .kit-constructor__result {
    order: 2; }
  .what-to-choose {
    order: 1;
    margin-left: 0;
    margin-right: 32px; }
    .what-to-choose:before {
      width: 28px;
      height: 30px;
      background: url(../img/bubble_path3.svg) no-repeat 0 0;
      background-size: contain;
      top: auto;
      bottom: 0;
      left: auto;
      right: -11px; } }

@media (max-width: 745px) {
  .your-kit {
    padding-top: 80px; }
    .your-kit .container {
      justify-content: center; }
  .your-kit__heading {
    font-size: 24px;
    letter-spacing: 0.14px;
    text-align: center;
    line-height: 30px;
    margin-bottom: 30px; }
    .your-kit__heading img {
      max-width: 81px;
      height: auto; }
  .constructor-item:not(.constructor-item--sm) {
    width: 100%;
    margin: 0;
    margin-bottom: -1px;
    padding-bottom: 15px;
    padding-left: 100px; }
  .constructor-item--sm {
    width: 50%; }
  .constructor-item__image {
    width: 100px; }
    .constructor-item__image div {
      padding: 0 13px; }
  .constructor-item__buttons--router .constructor-item__button span:nth-child(2) {
    display: none; }
  .constructor-item__buttons--router .constructor-item__button span:last-child {
    display: block; }
  .your-kit__right {
    display: inline-block;
    width: auto; }
  .kit-constructor__result {
    margin-bottom: 20px; }
  .what-to-choose {
    margin-right: 0; }
    .what-to-choose:before {
      width: 28px;
      height: 21px;
      left: 0;
      top: -8px;
      background: url(../img/bubble_path.svg) no-repeat 0 0;
      background-size: contain; } }

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  pointer-events: all; }

.slider-prev,
.slider-next {
  border: 0;
  background: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  outline: none;
  padding: 0;
  position: relative;
  z-index: 0;
  opacity: 1;
  transition: all 0.3s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background: #bcc9d5;
  cursor: pointer;
  user-select: none; }
  .slider-prev:before, .slider-prev:after,
  .slider-next:before,
  .slider-next:after {
    content: "";
    position: absolute;
    width: 15px;
    height: 8px;
    background: url(../img/arrow.svg) no-repeat 0 0;
    background-size: contain;
    position: absolute;
    left: 50%;
    margin-left: -9px;
    top: 50%;
    margin-top: -4px;
    opacity: 1;
    transition: all 0.3s; }
  .slider-prev:after,
  .slider-next:after {
    opacity: 0; }
  .slider-prev.slick-disabled,
  .slider-next.slick-disabled {
    opacity: 0.5;
    user-select: none;
    cursor: default;
    z-index: 10;
    pointer-events: all; }
  .slider-prev:not(.slick-disabled):not(.swiper-button-disabled):hover,
  .slider-next:not(.slick-disabled):not(.swiper-button-disabled):hover {
    opacity: 1;
    background: #9faebf; }
  .slider-prev--border,
  .slider-next--border {
    background: none;
    border: 1px solid #BFC9CB; }
    .slider-prev--border:not(.slick-disabled):not(.swiper-button-disabled):hover,
    .slider-next--border:not(.slick-disabled):not(.swiper-button-disabled):hover {
      background: #BFC9CB; }
      .slider-prev--border:not(.slick-disabled):not(.swiper-button-disabled):hover:before,
      .slider-next--border:not(.slick-disabled):not(.swiper-button-disabled):hover:before {
        opacity: 0; }
      .slider-prev--border:not(.slick-disabled):not(.swiper-button-disabled):hover:after,
      .slider-next--border:not(.slick-disabled):not(.swiper-button-disabled):hover:after {
        opacity: 1; }

.slider-prev {
  left: 0; }
  .slider-prev:before, .slider-prev:after {
    transform: rotate(90deg); }
  .slider-prev--border:before {
    background: url(../img/arrow_gray.svg) no-repeat 0 0;
    background-size: contain; }

.slider-next {
  right: 0; }
  .slider-next:before, .slider-next:after {
    transform: rotate(-90deg);
    margin-left: -6px; }
  .slider-next--border:before {
    background: url(../img/arrow_gray.svg) no-repeat 0 0;
    background-size: contain; }

.slick-prev,
.slick-next {
  border: 0;
  background: none;
  width: 25px;
  height: 19px;
  outline: none;
  padding: 0;
  position: relative;
  z-index: 0;
  opacity: 0.6;
  transition: all 0.3s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10; }
  .slick-prev svg,
  .slick-next svg {
    width: 100%;
    height: 100%;
    fill: #99a1b0;
    position: absolute;
    left: 0;
    top: 0; }
    .slick-prev svg path,
    .slick-next svg path {
      fill: inherit; }
    .slick-prev svg use,
    .slick-next svg use {
      fill: inherit; }
  .slick-prev.disabled,
  .slick-next.disabled {
    opacity: 0.5;
    cursor: default; }
  .slick-prev:not(.slick-disabled):hover, .slick-prev:not(.slick-disabled):hover,
  .slick-next:not(.slick-disabled):hover,
  .slick-next:not(.slick-disabled):hover {
    opacity: 1; }

.slick-prev {
  left: 0; }

.slick-next {
  right: 0; }

.places .section-title {
  text-align: left;
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 39px;
  letter-spacing: 0.23px;
  line-height: 36px; }

.places-tabs {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 15px;
  font-size: 0; }
  .places-tabs li {
    padding: 0;
    margin: 0;
    margin-right: 30px;
    display: inline-block;
    vertical-align: top; }
    .places-tabs li a {
      font-size: 32px;
      display: inline-block;
      line-height: 1.4;
      color: #A5B7CA;
      font-weight: bold;
      border-bottom-style: dashed;
      border-bottom-color: #A5B7CA; }
      .places-tabs li a:hover {
        color: #738DA9;
        border-color: #738DA9; }
    .places-tabs li:before {
      display: none; }
    .places-tabs li.is-active a {
      border-color: transparent;
      color: #1CDB5B; }

.places__body {
  display: flex;
  flex-wrap: wrap; }

.places__illustrations {
  width: 54.5%;
  max-width: 54.5%;
  position: relative;
  height: 720px; }

.place-illustration {
  position: absolute;
  width: 660px;
  height: 660px;
  left: -35px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.3s;
  transform: translateX(100px);
  opacity: 0;
  visibility: hidden; }
  .place-illustration.is-active {
    transform: translateX(0px);
    opacity: 1;
    visibility: visible; }
  .place-illustration.is-out {
    transform: translateX(-100px);
    opacity: 0;
    visibility: hidden; }

.places__right {
  width: 45.5%;
  max-width: 45.5%;
  padding-top: 75px; }
  .places__right .btn {
    margin-left: 50px; }

.place-info-item {
  padding-left: 61px;
  position: relative;
  margin-bottom: 40px; }

.place-info-item__num {
  position: absolute;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  line-height: 42px;
  text-align: center;
  z-index: 1;
  left: 0;
  top: 5px; }
  .place-info-item__num:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #004DA3;
    border-radius: 50%;
    z-index: 0;
    transition: all 0.3s; }
  .place-info-item__num span {
    position: relative;
    z-index: 1; }

.place-info-item__name {
  margin-bottom: 10px; }
  .place-info-item__name h3 {
    font-size: 20px;
    color: #1E1F21;
    letter-spacing: 0.12px;
    line-height: 26px;
    font-weight: bold;
    border: none; }

.place-info-item__descr {
  font-size: 14px;
  line-height: 22px; }
  .place-info-item__descr p:last-child {
    margin: 0; }

@media (max-width: 1245px) {
  .places {
    padding-bottom: 20px; }
  .places__illustrations {
    width: 56%;
    max-width: 56%;
    height: 521px; }
  .places__right {
    width: 44%;
    max-width: 44%;
    padding-top: 70px; }
  .place-illustration {
    left: -10px;
    width: 100%;
    max-width: 475px;
    height: 473px;
    top: 40px; }
  .place-illustration svg {
    max-width: 523px;
    height: 100%; } }

@media (max-width: 945px) {
  .places {
    padding-bottom: 0; }
    .places .section-title {
      padding-top: 0; }
  .places__illustrations {
    width: 100%;
    max-width: 100%;
    height: 0;
    padding-bottom: 100%;
    margin-bottom: 11px; }
    .places__illustrations svg {
      max-width: 604px; }
  .place-illustration {
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    top: 0px; }
  .places__right {
    width: 100%;
    max-width: 100%;
    padding-bottom: 40px;
    padding-top: 0; }
    .places__right .btn {
      margin-left: 72px; }
  .place-info-item {
    padding: 0;
    padding-left: 72px;
    margin-bottom: 40px; }
    .place-info-item:before {
      display: none; }
    .place-info-item.is-active .place-info-item__num:before {
      background: #004DA3; }
    .place-info-item.is-active .place-info-item__name a {
      color: #004DA3;
      border-bottom-color: rgba(0, 77, 163, 0.4); }
  .place-info-item__num {
    left: 0px;
    top: 5px; } }

@media (max-width: 745px) {
  .places-tabs li {
    margin-bottom: 10px;
    margin-right: 20px; }
    .places-tabs li a {
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.12px; }
  .places__right {
    padding-bottom: 60px; }
    .places__right .btn {
      margin-left: 62px; }
  .place-info-item {
    padding-left: 62px;
    margin-bottom: 23px; }
  .place-illustration__dot.is-active circle:first-child {
    animation: none; }
  .place-illustration__dot g {
    transform-origin: 0 0;
    transform: scale(1.6) translateY(-14px); }
  .places .section-title {
    font-size: 24px;
    letter-spacing: 0.14px;
    line-height: 30px; } }

.get-fast-speed {
  color: #fff;
  background: #2cbf4f; }
  .get-fast-speed .container {
    position: relative;
    z-index: 0;
    padding-bottom: 105px; }
  .get-fast-speed .kits-image {
    position: absolute;
    width: 648px;
    right: 0;
    bottom: 76px; }
  .get-fast-speed .section-title {
    padding-top: 91px;
    color: #fff;
    margin-bottom: 49px;
    max-width: 600px;
    font-size: 39px;
    letter-spacing: 0.23px;
    line-height: 44px; }
    .get-fast-speed .section-title span {
      font-weight: 300; }

.get-fast-speed__illustration {
  position: absolute;
  width: 862px;
  height: 100%;
  top: 0;
  z-index: -1;
  left: 50%; }
  .get-fast-speed__illustration:before {
    content: "";
    position: absolute;
    width: 862px;
    height: 100%;
    background: url(../img/speed_illustration.png) no-repeat center;
    background-size: 100% auto;
    top: 50%;
    transform: translateY(-50%);
    left: 0; }
  .get-fast-speed__illustration.webp:before {
    background: url(../img/speed_illustration.webp) no-repeat center; }
  .get-fast-speed__illustration:after {
    content: "";
    position: absolute;
    width: 620px;
    height: 617px;
    background-image: url(../img/family.png);
    background-repeat: no-repeat;
    background-size: contain;
    bottom: -1px;
    left: 30px; }
  .get-fast-speed__illustration.webp:after {
    background-image: url(../img/family.webp); }

.speed-features {
  max-width: 320px; }

.speed-features__item {
  margin-bottom: 30px;
  position: relative;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.1px;
  padding-left: 35px; }
  .speed-features__item:before {
    content: "";
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    left: 0;
    top: 0; }
  .speed-features__item h3, .speed-features__item .speed-features__title {
    display: block;
    margin: 0;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.1px;
    font-weight: bold;
    color: #fff; }
  .speed-features__item--item1:before {
    background-image: url(../img/speed_feature_icon1.svg);
    width: 22px;
    height: 22px; }
  .speed-features__item--item2:before {
    background-image: url(../img/speed_feature_icon2.svg);
    width: 22px;
    height: 22px; }
  .speed-features__item--item3:before {
    background-image: url(../img/speed_feature_icon3.svg);
    width: 22px;
    height: 10px;
    top: 6px; }

.get-fast-speed--s-kit .container {
  padding-bottom: 77px; }

.get-fast-speed--s-kit .section-title {
  max-width: 630px; }

.get-fast-speed--s-kit .kits-image__dot:last-child .kits-image__dot-content {
  left: auto;
  right: 0; }

.get-fast-speed__button {
  font-size: 0;
  line-height: 1; }

@media (max-width: 1245px) {
  .get-fast-speed .container {
    padding-bottom: 61px; }
  .get-fast-speed .section-title {
    max-width: 400px; }
  .speed-features {
    max-width: 320px; }
  .get-fast-speed__illustration {
    left: 45%; }
  .get-fast-speed--s-kit .section-title {
    max-width: 530px; } }

@media (min-width: 946px) and (max-width: 1245px) {
  .get-fast-speed--s-kit .kits-image {
    width: 547px;
    bottom: 120px;
    right: -20px; }
  .get-fast-speed--s-kit .kits-image__dot:nth-child(1) {
    left: -8px;
    top: 93px; }
  .get-fast-speed--s-kit .kits-image__dot:nth-child(2) {
    left: 169px;
    top: 54px; }
  .get-fast-speed--s-kit .kits-image__dot:nth-child(3) {
    left: 173px;
    top: 151px; }
  .get-fast-speed--s-kit .kits-image__dot:nth-child(4) {
    right: 69px;
    bottom: 42px; }
  .get-fast-speed--s-kit .kits-image__dot:nth-child(5) {
    right: -16px;
    top: 165px; } }

@media (max-width: 945px) {
  .get-fast-speed {
    text-align: center;
    overflow: hidden;
    background: #2cbf4f; }
    .get-fast-speed .container {
      padding-bottom: 426px; }
    .get-fast-speed .section-title {
      padding-top: 60px;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      text-align: center; }
  .get-fast-speed__illustration {
    width: 541px;
    height: 541px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -126px;
    top: auto;
    bottom: -126px; }
    .get-fast-speed__illustration:before {
      width: 541px;
      height: 541px;
      background-size: contain; }
    .get-fast-speed__illustration:after {
      width: 338px;
      height: 336px;
      bottom: 126px; }
  .speed-features {
    margin-right: 0px;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
    max-width: 100%; }
  .speed-features__item {
    width: 50%;
    max-width: 280px;
    margin-right: 0;
    text-align: center;
    padding: 0;
    padding-top: 32px; }
    .speed-features__item:before {
      left: 50%;
      transform: translateX(-50%); }
  .get-fast-speed--s-kit .container {
    padding-bottom: 389px; }
  .get-fast-speed--s-kit .kits-image {
    bottom: 23px;
    left: 50%;
    margin-left: -320px;
    top: auto; } }

@media (max-width: 745px) {
  .get-fast-speed .container {
    padding-bottom: 390px; }
  .get-fast-speed .section-title {
    padding-top: 45px;
    font-size: 24px;
    letter-spacing: 0.14px;
    line-height: 30px;
    margin-bottom: 40px; }
  .speed-features {
    display: block; }
  .speed-features__item {
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
    margin-bottom: 40px;
    font-size: 14px;
    letter-spacing: 0.08px;
    text-align: center;
    line-height: 21px; }
    .speed-features__item h3, .speed-features__item .speed-features__title {
      display: block;
      font-size: 14px;
      letter-spacing: 0.08px;
      line-height: 21px; }
  .get-fast-speed__illustration {
    width: 427px;
    height: 427px;
    bottom: -53px; }
    .get-fast-speed__illustration:before {
      width: 427px;
      height: 427px;
      background-size: contain; }
    .get-fast-speed__illustration:after {
      width: 259px;
      height: 257px;
      bottom: 53px;
      left: 76px; }
  .get-fast-speed--s-kit .container {
    padding-bottom: 20px; }
  .get-fast-speed--s-kit .kits-image {
    position: relative;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    display: inline-block;
    top: auto;
    padding-top: 20px;
    width: 100%; } }

.b-why {
  padding-bottom: 80px; }

.b-why__body {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start; }

.b-why__left {
  width: 50%;
  position: relative;
  z-index: 0;
  padding-right: 10px; }

.b-why__right {
  width: 50%;
  padding-left: 10px; }

.b-why__logo {
  margin-bottom: 26px;
  font-size: 0;
  line-height: 1; }

.b-why__button {
  font-size: 0;
  line-height: 1; }
  .b-why__button .btn {
    white-space: normal;
    margin-bottom: 20px; }
    .b-why__button .btn:last-child {
      margin-bottom: 0; }

.b-why__good {
  background: #1F72CF;
  padding: 20px;
  padding-bottom: 98px;
  color: #fff;
  position: relative;
  z-index: 0;
  border-radius: 4px; }
  .b-why__good:before {
    content: "";
    position: absolute;
    width: 363px;
    height: 204px;
    background-image: url("../img/b_why_image.png");
    background-size: contain;
    background-repeat: no-repeat;
    bottom: 6px;
    right: -4px;
    z-index: -1; }
  .b-why__good.webp :before {
    background-image: url("../img/b_why_image.webp"); }

.b-why__heading {
  display: block;
  font-size: 28px;
  line-height: 38px;
  font-weight: 400;
  margin: 0 0 32px;
  max-width: 775px; }
  @media (max-width: 1245px) {
    .b-why__heading {
      font-size: 22px;
      line-height: 32px; } }

.b-why__dl {
  margin: 0 20px 0 0;
  padding: 20px 0; }
  .b-why__dl dt {
    display: block;
    position: relative;
    padding: 14px 20px 14px 56px;
    background: #EFF7F9;
    color: rgba(37, 76, 86, 0.7);
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    border-radius: 10px 0 0 10px;
    margin: 0 0 10px; }
    .b-why__dl dt:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 100%;
      width: 20px;
      background: inherit;
      clip-path: polygon(0 0, 0% 100%, 100% 50%);
      margin: 0 0 0 -1px; }
  .b-why__dl dd {
    margin: 0 0 32px;
    padding: 0 0 0 56px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400; }
    .b-why__dl dd:last-child {
      margin: 0; }
  .b-why__dl p {
    margin: 0 0 26px; }
    .b-why__dl p:last-child {
      margin: 0; }
  .b-why__dl a {
    color: #00C767;
    border-bottom: 1px dotted #00C767; }
  .b-why__dl svg {
    width: 26px;
    height: 26px;
    position: absolute;
    top: 11px;
    left: 16px; }

.b-why--internet .section-title {
  font-size: 39px;
  line-height: 0.92307692;
  letter-spacing: 0.23px;
  font-weight: 600;
  max-width: 1140px;
  display: block;
  margin: 0 auto 50px; }
  @media (max-width: 745px) {
    .b-why--internet .section-title {
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0.16px;
      margin-bottom: 30px; } }

.b-why--internet .b-why__good {
  overflow: hidden;
  padding: 20px 28px 50px;
  background: #1F72CF; }
  .b-why--internet .b-why__good:before {
    left: 258px; }

.b-why--internet .why-features__list {
  width: 100%; }

.b-why__row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px; }

.b-why__col {
  padding: 0 10px; }

.b-why__col-1 {
  flex: 1;
  min-width: 0; }

.b-why__col-2 {
  flex: 0 0 42.7%;
  max-width: 480px; }
  @media (max-width: 745px) {
    .b-why__col-2 {
      max-width: none;
      flex: 0 0 100%; } }

.why-features {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px; }

.why-features__list {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 50%;
  padding-right: 20px; }
  .why-features__list li {
    margin-bottom: 13px;
    padding-left: 35px;
    position: relative;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    letter-spacing: 0.08px;
    line-height: 22px; }
    .why-features__list li:before {
      display: none; }

.why-features__item {
  position: relative; }
  .why-features__item small {
    display: block;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400; }
  .why-features__item:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain; }
  .why-features__item--speed:after {
    width: 21px;
    height: 21px;
    background-image: url(../img/why_feature_icon1.svg); }
  .why-features__item--tarifs:after {
    width: 22px;
    height: 10px;
    background-image: url(../img/why_feature_icon2.svg);
    top: 8px;
    left: 0px; }
  .why-features__item--traffic:after {
    width: 22px;
    height: 22px;
    background-image: url(../img/why_feature_icon3.svg); }
  .why-features__item--install:after {
    width: 22px;
    height: 22px;
    background-image: url(../img/why_feature_icon4.svg); }
  .why-features__item--signal:after {
    width: 22px;
    height: 22px;
    background-image: url(../img/why_feature_icon5.svg); }

.b-why__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.b-why__item {
  padding: 20px;
  width: calc(50% - 10px);
  background: #ecf5f8;
  border-radius: 5px;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 22px;
  color: #47727B;
  z-index: 0;
  overflow: hidden;
  min-height: 172px; }
  .b-why__item h3, .b-why__item .b-why__title {
    color: #47727b;
    font-size: 20px;
    line-height: 26px;
    color: rgba(37, 76, 86, 0.5);
    margin-bottom: 10px;
    position: relative;
    padding-top: 30px;
    font-weight: 600;
    margin-right: -10px; }
    .b-why__item h3:before, .b-why__item .b-why__title:before {
      content: "";
      position: absolute;
      top: 0; }
  .b-why__item p {
    margin: 0; }
    .b-why__item p span {
      font-weight: 700; }
  .b-why__item--item1 h3:before, .b-why__item--item1 .b-why__title:before {
    width: 21px;
    height: 19px;
    background: url(../img/why_internet_icon1.svg) no-repeat 0 0;
    background-size: contain; }
  .b-why__item--item2 h3:before, .b-why__item--item2 .b-why__title:before {
    width: 23px;
    height: 19px;
    background: url(../img/why_internet_icon2.svg) no-repeat 0 0;
    background-size: contain; }
  .b-why__item--item3 h3:before, .b-why__item--item3 .b-why__title:before {
    width: 13px;
    height: 24px;
    background: url(../img/why_internet_icon3.svg) no-repeat 0 0;
    background-size: contain; }
  .b-why__item--item4 h3:before, .b-why__item--item4 .b-why__title:before {
    width: 20px;
    height: 22px;
    background: url(../img/why_internet_icon4.svg) no-repeat 0 0;
    background-size: contain; }

@media (max-width: 1245px) {
  .b-why__left {
    width: 33%; }
  .b-why__right {
    width: 67%; }
  .b-why__good {
    padding-bottom: 30px; }
    .b-why__good:before {
      display: none; }
  .why-features {
    display: block;
    margin-bottom: 23px; }
  .why-features__list {
    width: 100%; } }

@media (max-width: 945px) {
  .b-why {
    padding-bottom: 60px; }
  .b-why__left {
    width: 42.7%; }
  .b-why__right {
    width: 57.3%; }
  .b-why__good {
    padding-bottom: 199px; }
    .b-why__good:before {
      display: block;
      width: 263px;
      height: 148px;
      bottom: 32px;
      left: 14px;
      right: auto; }
  .b-why__items {
    display: block; }
  .b-why__item {
    width: 100%;
    min-height: 0; } }

@media (max-width: 745px) {
  .b-why {
    padding-bottom: 40px; }
  .b-why__left {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px; }
  .b-why__right {
    width: 100%;
    padding-left: 0; } }

.b-works {
  padding-bottom: 99px;
  background: #f1f4f8; }
  .b-works .section-title {
    margin-bottom: 10px;
    margin-right: 80px; }

.works-result {
  font-weight: 600;
  font-size: 20px;
  color: #A9B0BD;
  letter-spacing: 0.12px;
  line-height: 21px;
  margin-bottom: 30px; }

.works-slider, .read-more-slider {
  margin-left: -10px;
  margin-right: -10px;
  z-index: 0; }
  .works-slider:not(.slick-initialized) .sld:not(:first-child), .read-more-slider:not(.slick-initialized) .sld:not(:first-child) {
    display: none; }
  .works-slider:not(.slick-initialized) .sld:first-child, .read-more-slider:not(.slick-initialized) .sld:first-child {
    max-width: 280px; }
  .works-slider .sld, .read-more-slider .sld {
    padding: 0 10px; }
  .works-slider .slider-prev, .read-more-slider .slider-prev {
    transform: translate(0);
    top: 72px;
    left: -50px;
    z-index: -1; }
  .works-slider .slider-next, .read-more-slider .slider-next {
    transform: translate(0);
    top: 72px;
    right: -50px;
    z-index: -1; }

.slick-slider.slick-dotted {
  padding-bottom: 30px;
  margin-bottom: 40px; }

.slick-dots {
  height: 5px;
  bottom: 0;
  padding: 0;
  margin: 0;
  line-height: 1;
  font-size: 0; }
  .slick-dots li {
    padding: 0;
    margin-right: 5px;
    width: 5px;
    height: 5px; }
    .slick-dots li:last-child {
      margin-right: 0; }
    .slick-dots li:before {
      display: none; }
    .slick-dots li button {
      width: 5px;
      height: 5px;
      padding: 0;
      border-radius: 50%;
      background: #cccccc;
      transition: all 0.3s; }
      .slick-dots li button:before {
        display: none; }
      .slick-dots li button:hover {
        color: #000; }
    .slick-dots li.slick-active button {
      background: #000; }

.work-preview {
  border: 0;
  display: block;
  color: #1E1F21; }

.work-preview__image {
  height: 169px;
  position: relative;
  margin-bottom: 10px; }
  .work-preview__image:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: all 0.3s;
    background: rgba(255, 255, 255, 0.3);
    border: 3px solid rgba(255, 255, 255, 0.45);
    opacity: 0;
    z-index: 1; }
  .work-preview__image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    font-family: "object-fit: cover; object-position: center"; }

.work-preview__speed {
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.35);
  border-radius: 100px;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  font-weight: 600;
  padding: 4px 13px;
  z-index: 2; }

.work-preview__tags {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.35);
  border-radius: 100px;
  font-size: 0;
  font-weight: 600;
  padding: 8px 15px;
  line-height: 1;
  z-index: 2;
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center; }

.work-preview__tag {
  margin-right: 15px;
  display: inline-block;
  vertical-align: middle; }
  .work-preview__tag:last-child {
    margin-right: 0; }
  .work-preview__tag--internet {
    width: 13px;
    height: 13px;
    background: url(../img/work-internet.svg) no-repeat 0 0;
    background-size: contain; }
  .work-preview__tag--camera {
    width: 16px;
    height: 10px;
    background: url(../img/work-camera.svg) no-repeat 0 0;
    background-size: contain; }
  .work-preview__tag--tv {
    width: 14px;
    height: 14px;
    background: url(../img/work-tv.svg) no-repeat 0 0;
    background-size: contain; }
  .work-preview__tag--wifi {
    width: 15px;
    height: 13px;
    background: url(../img/work-wifi.svg) no-repeat 0 0;
    background-size: contain; }

.work-preview__address {
  font-size: 14px;
  line-height: 22px;
  transition: all 0.3s; }
  .work-preview__address span {
    font-weight: bold;
    color: #004DA3; }

@media (max-width: 1245px) {
  .works-slider .slider-prev {
    top: -80px;
    right: 60px;
    left: auto; }
  .works-slider .slider-next {
    top: -80px;
    right: 10px; } }

@media (max-width: 745px) {
  .b-works {
    padding-bottom: 60px;
    padding-bottom: 40px; }
    .b-works .section-title {
      padding-top: 30px; }
  .works-slider,
  .read-more-slider {
    margin-left: -20px;
    margin-right: -20px; }
    .works-slider .sld,
    .read-more-slider .sld {
      width: 280px;
      padding-right: 0;
      padding-left: 20px; }
  .work-preview {
    max-width: 260px; } }

.b-map .section-title {
  margin-bottom: 0px; }

.maps-container {
  position: relative; }

.map {
  position: relative;
  height: 460px;
  background: #ccc; }

#map {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }

.map-search__submit {
  position: absolute;
  width: 15px;
  height: 15px;
  right: 21px;
  top: 50%;
  padding: 0;
  margin-top: -7px;
  border: 0;
  outline: 0;
  background: none; }
  .map-search__submit svg {
    width: 15px;
    height: 15px;
    fill: #8E8F90;
    transition: all 0.3s; }
    .map-search__submit svg use {
      fill: inherit; }
  .map-search__submit:hover svg {
    fill: #000; }

.suggestions-wrapper {
  position: absolute; }

.suggestions-suggestions {
  border: none;
  background: #FFFFFF;
  border: 1px solid #DFDFE0;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }

.suggestions-suggestion {
  font-weight: 500;
  font-size: 14px;
  color: #1E1F21;
  letter-spacing: 0.08px;
  line-height: 22px;
  padding: 2px 18px;
  cursor: pointer; }
  .suggestions-suggestion strong {
    font-weight: 500;
    color: #1c5dc7; }
  .suggestions-suggestion:hover {
    background: none;
    color: #1c5dc7; }

.suggestions-constraints {
  display: none; }

.suggestions-hint {
  padding: 4px 18px;
  white-space: nowrap;
  overflow: hidden;
  color: #777;
  font-size: 85%; }

.map-search {
  position: absolute;
  left: 20px;
  top: 57px;
  max-width: 450px;
  width: 100%;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.19);
  padding: 0;
  z-index: 1;
  background: #fff; }
  .map-search .form__body {
    padding: 20px 30px;
    padding-bottom: 30px;
    max-width: 100%; }
  .map-search .form-field-container {
    margin: 0; }
  .map-search .form-field {
    padding-right: 70px; }
  .map-search .form__heading p {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.09px;
    line-height: 22px;
    margin-bottom: 12px; }
  .map-search .suggestions-addon {
    margin-left: -30px; }
  .map-search .b-map__info {
    display: none; }

.connect-neighbors {
  border-radius: 0;
  text-align: left;
  font-size: 20px;
  line-height: 26px;
  padding: 14px 30px;
  padding-right: 56px;
  text-transform: none;
  position: relative;
  display: block; }
  .connect-neighbors span {
    font-weight: 900; }
  .connect-neighbors:before, .connect-neighbors:after {
    border-radius: 0; }
  .connect-neighbors:hover .connect-neighbors__arrow {
    transform: translateX(10px); }

.connect-neighbors__arrow {
  position: absolute;
  width: 25px;
  height: 19px;
  background: url(../img/svg-sprites/arrow2.svg) no-repeat 0 0;
  background-size: contain;
  right: 28px;
  top: 50%;
  margin-top: -10px;
  z-index: 1;
  opacity: 0.6;
  transition: all 0.3s; }

.b-map__info {
  margin-bottom: 15px;
  font-size: 0; }

.b-map__info-area,
.b-map__info-subscribers {
  margin-bottom: 5px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin-right: 30px;
  padding-left: 23px;
  font-size: 14px;
  letter-spacing: 0.08px;
  line-height: 22px; }
  .b-map__info-area:before,
  .b-map__info-subscribers:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%; }

.b-map__info-area:before {
  width: 15px;
  height: 15px;
  margin-top: -7px;
  background: #99dca3;
  border-radius: 50%; }

.b-map__info-subscribers {
  margin-right: 0; }
  .b-map__info-subscribers:before {
    width: 14px;
    height: 20px;
    margin-top: -7px;
    background: url(../img/map_pin.svg) no-repeat 0 0;
    background-size: contain; }

@media (max-width: 945px) {
  .b-map .section-title {
    margin-bottom: 15px; }
  .map-search {
    box-shadow: none;
    position: relative;
    max-width: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-wrap: wrap; }
    .map-search .form__body {
      max-width: none;
      width: 50%;
      padding: 0;
      padding-right: 40px;
      margin: 0;
      padding-bottom: 30px; }
    .map-search .b-map__info {
      display: block;
      width: 50%; }
  .b-map__info {
    display: none; }
  .connect-neighbors {
    width: 100%; } }

@media (max-width: 745px) {
  .maps {
    margin-left: -20px;
    margin-right: -20px; }
  .connect-neighbors {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-bottom: 11px;
    padding-top: 11px;
    width: calc(100% + 40px); }
  .map {
    height: 400px;
    margin-left: -20px;
    margin-right: -20px; }
  .map-search {
    display: block; }
    .map-search .form__body {
      width: 100%;
      padding-right: 0;
      padding-bottom: 20px; }
    .map-search .b-map__info {
      width: 100%; } }

.b-qa {
  padding-bottom: 102px; }
  .b-qa .section-title {
    padding-top: 80px;
    margin-bottom: 20px; }

.qa-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px; }

.qa-list__col {
  width: calc(50% - 20px);
  margin-right: 20px;
  margin-bottom: 20px; }

.qa-item {
  background: #FFFFFF;
  border: 1px solid #E5EBED;
  border-radius: 5px;
  padding: 23px 31px;
  padding-bottom: 13px;
  transition: all 0.3s;
  user-select: none;
  min-height: 118px; }
  .qa-item:not(.is-open) {
    cursor: pointer; }
  .qa-item:hover {
    box-shadow: 0 7px 8px rgba(0, 0, 0, 0.04); }
  .qa-item.is-open .qa-item__question h3 {
    color: #1E1F21; }
  .qa-item.is-open .qa-item__question:before {
    transform: rotate(-180deg); }

.qa-item__question {
  cursor: pointer;
  margin-bottom: 10px;
  padding-right: 40px;
  position: relative; }
  .qa-item__question:before {
    content: "";
    width: 14px;
    height: 8px;
    background: url(../img/qa_arrow.svg) no-repeat 0 0;
    background-size: contain;
    right: 0;
    top: 50%;
    margin-top: -3px;
    position: absolute; }
  .qa-item__question h3 {
    font-size: 16px;
    margin: 0;
    color: #3977BC;
    letter-spacing: 0.09px;
    line-height: 23px;
    font-weight: 600; }

.qa-item__answer {
  font-size: 14px;
  line-height: 22px;
  display: none;
  position: relative;
  z-index: 1; }
  .qa-item__answer > div {
    padding-bottom: 10px; }
  .qa-item__answer p:last-child {
    margin: 0; }

.qa__button {
  padding-top: 10px;
  text-align: center;
  font-size: 0;
  line-height: 1; }

@media (max-width: 945px) {
  .b-qa {
    padding-bottom: 80px; }
  .qa__button {
    text-align: left; }
  .qa-list {
    display: block;
    margin: 0; }
  .qa-list__col {
    width: 100%;
    margin-right: 0; } }

@media (max-width: 745px) {
  .b-qa .section-title {
    font-size: 26px;
    letter-spacing: 0.15px;
    line-height: 32px;
    padding-top: 66px;
    margin-bottom: 30px; }
  .qa-item {
    background: #ecf5f8;
    padding: 20px;
    padding-bottom: 10px;
    border: 0; }
  .qa-item__question {
    padding: 0; }
    .qa-item__question:before {
      display: none; } }

.b-request {
  padding-top: 109px;
  padding-bottom: 105px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #b7b7d7;
  background-size: cover; }

.b-request.video {
  background-image: url(../img/b_request_bg_video.jpg); }

.b-request.cellular {
  background-image: url(../img/b_request_bg_cellular.jpg); }

.b-request.satellite {
  background-image: url(../img/b_request_bg_satellite.jpg); }

.form-request {
  max-width: none; }
  .form-request .form__heading {
    margin-bottom: 46px;
    text-align: center; }
    .form-request .form__heading h2, .form-request .form__heading .form_title {
      font-weight: bold;
      font-size: 37px;
      color: #542A39;
      letter-spacing: 0.22px;
      line-height: 44px;
      margin: 0;
      margin-bottom: 20px; }
      .form-request .form__heading h2:last-child, .form-request .form__heading .form_title:last-child {
        margin-bottom: 7px; }
    .form-request .form__heading p {
      max-width: 579px;
      margin: 0 auto;
      font-weight: 500; }
  .form-request .form__body {
    max-width: 581px;
    margin: 0 auto; }
  .form-request .form-field {
    border-color: #fff;
    height: 55px; }
  .form-request .form__button {
    padding-top: 0px;
    padding-bottom: 0;
    margin-bottom: 10px;
    margin-top: -5px; }
  .form-request .btn {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.17);
    min-width: 138px; }
  .form-request.is-submitted .btn {
    width: 181px;
    color: rgba(255, 255, 255, 0); }
  .form-request.is-submitted .btn__success {
    display: flex; }

.form-request__hidden {
  display: none; }

.service_page .form-request .form__heading h2 {
  color: white; }

.service_page .form__note {
  color: white; }

.service_page .form__note a {
  color: white;
  border-bottom-color: rgba(255, 255, 255, 0.3); }

.service_page .form__note a:hover {
  color: white;
  border-bottom-color: rgba(255, 255, 255, 0.3); }

.services_text {
  background: #004da3;
  padding-top: 40px;
  padding-bottom: 20px; }

.services_text p {
  color: #97B9DF;
  font-size: 15px;
  line-height: 19px; }

.services_text .services_title {
  font-weight: 700;
  font-size: 37px;
  letter-spacing: .22px;
  text-align: center;
  line-height: 44px;
  color: #fff;
  margin-bottom: 49px; }

.services_text .services_subtitle {
  font-size: 16px;
  line-height: 1.0625;
  letter-spacing: .19px;
  color: white;
  font-weight: 500; }

.services_text img {
  margin-bottom: 40px;
  margin-right: 20px; }

@media (max-width: 945px) {
  .form-request .form__heading {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto; } }

@media (max-width: 745px) {
  .b-request.video {
    background-image: url(../img/b_request_bg_video_mobi.jpg); }
  .b-request.cellular {
    background-image: url(../img/b_request_bg_cellular_mobi.jpg); }
  .b-request.satellite {
    background-image: url(../img/b_request_bg_satellite_mobi.jpg); }
  .form-request .form__heading {
    margin-bottom: 0; }
    .form-request .form__heading h2, .form-request .form__heading .form_title {
      font-size: 26px;
      letter-spacing: 0.15px;
      text-align: center;
      line-height: 32px; }
      .form-request .form__heading h2:last-child, .form-request .form__heading .form_title:last-child {
        margin-bottom: 90px; }
    .form-request .form__heading p:last-child {
      margin-bottom: 50px; }
  .form-group {
    display: block; }
    .form-group .form-field-container {
      width: 100%; } }

@media (max-width: 340px) {
  .b-request {
    padding-top: 52px;
    padding-bottom: 41px;
    background: url(../img/b_request_bg2.jpg) no-repeat center;
    background-size: cover; } }

.footer {
  background: #004DA3;
  color: #fff;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500; }
  .footer ul li {
    padding: 0; }
    .footer ul li:before {
      display: none; }

.footer__body {
  padding-top: 47px;
  padding-bottom: 38px; }

.footer-nav {
  margin-bottom: 10px; }

.footer-nav-list {
  margin: 0; }

.footer-nav-list__item {
  margin-bottom: 11px; }

.footer-nav-list__link {
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0.08px;
  line-height: 1.2;
  border-bottom-color: transparent; }
  .footer-nav-list__link:hover {
    color: #fff;
    border-bottom-color: rgba(255, 255, 255, 0.7); }

.footer__help-links {
  margin-bottom: 67px; }
  .footer__help-links li {
    margin-bottom: 5px; }
    .footer__help-links li a {
      color: rgba(255, 255, 255, 0.5);
      border-bottom-color: rgba(255, 255, 255, 0.3); }
      .footer__help-links li a:hover {
        color: #fff;
        border-bottom-color: rgba(255, 255, 255, 0.5); }

.footer__logo {
  display: inline-block;
  border: 0;
  margin-bottom: 20px; }
  .footer__logo svg {
    width: 132px;
    height: 33px; }

.copyright {
  font-size: 12px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.5); }

.footer__email a {
  color: #fff;
  border-bottom-color: rgba(255, 255, 255, 0.3); }
  .footer__email a:hover {
    border-bottom-color: rgba(255, 255, 255, 0.7); }

.developer {
  color: rgba(255, 255, 255, 0.6);
  text-align: right;
  margin-top: -35px;
  font-size: 14px;
  line-height: 18px; }
  .developer p {
    margin: 0;
    display: inline-block;
    vertical-align: middle; }

.developer__logo {
  display: inline-block;
  vertical-align: middle;
  border: 0;
  margin: 0 7px;
  transition: all 0.3s;
  opacity: 0.7;
  font-size: 0;
  line-height: 1;
  position: relative;
  z-index: 1; }
  .developer__logo:hover {
    opacity: 1; }

.developer__link, .developer__link:focus {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  border-bottom-color: rgba(255, 255, 255, 0.3);
  position: relative;
  z-index: 2; }
  .developer__link:hover, .developer__link:focus:hover {
    color: #fff;
    border-bottom-color: rgba(255, 255, 255, 0.7); }

.footer__address {
  font-style: normal;
  margin-bottom: 15px;
  font-weight: 500; }

.footer__open-map {
  margin-bottom: 20px;
  line-height: 1;
  font-size: 0; }

.footer__email {
  margin-bottom: 20px; }

.footer__phones {
  margin-bottom: 10px;
  text-align: left;
  font-size: 0; }

.footer__phone {
  font-weight: 600;
  font-size: 18px;
  color: #FFFFFF;
  letter-spacing: 0.1px;
  border: 0;
  margin-bottom: 5px;
  display: inline-block; }
  .footer__phone:hover {
    color: #fff; }

.footer__time {
  display: inline-block;
  vertical-align: middle;
  padding-left: 19px;
  position: relative;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 10px; }
  .footer__time:before {
    content: "";
    position: absolute;
    width: 14px;
    height: 14px;
    background: url(../img/clock2.svg) no-repeat 0 0;
    opacity: 0.7;
    background-size: contain;
    left: 0;
    top: 50%;
    margin-top: -7px; }

.footer__social {
  display: flex; }
  .footer__social img {
    width: 24px;
    min-width: 24px; }
  .footer__social li {
    margin-right: 1rem; }
  .footer__social li a:hover {
    opacity: .8; }

.footer__region {
  display: block;
  margin-bottom: 20px; }
  .footer__region .region__heading {
    color: #fff; }
  .footer__region .region__link {
    color: #fff;
    font-weight: bold;
    border-bottom-color: rgba(255, 255, 255, 0.5); }
  .footer__region .dropdown__menu {
    top: auto;
    bottom: calc(100% + 8px); }

.footer__callback-link {
  line-height: 14px; }
  .footer__callback-link a {
    display: inline-block;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
    border-bottom-style: dashed;
    border-bottom-color: rgba(255, 255, 255, 0.5); }
    .footer__callback-link a:hover {
      border-bottom-color: white; }

.footer__consultation-button {
  margin-bottom: 10px;
  font-size: 0;
  line-height: 1; }

.footer-cols {
  display: flex;
  position: relative;
  z-index: 1; }

.footer-col--col1 {
  width: 25%;
  padding-right: 20px; }

.footer-col--col2 {
  width: 16.6666666%;
  padding-right: 20px; }

.footer-col--col3 {
  width: 43%;
  display: flex; }

.footer-col__left {
  width: 60%;
  padding-right: 20px; }

.footer-col__right {
  width: 40%; }

.footer-col--col4 {
  width: 15.33%;
  text-align: right; }
  .footer-col--col4 > div {
    display: inline-block;
    text-align: left; }

.footer__bottom {
  display: none; }

.footer__show-sm {
  display: none; }

@media (max-width: 1245px) {
  .footer-col--col1 {
    width: 33.33%; }
  .footer-col--col2 {
    width: 22.22%; }
  .footer-col--col3 {
    flex-wrap: wrap;
    width: 22%; }
  .footer-col__left {
    padding-right: 0;
    width: 100%;
    order: 2; }
  .footer-col__right {
    width: 100%;
    order: 1; }
  .footer-col--col4 {
    width: 22.45%; }
  .footer__show-md {
    display: block; } }

@media (max-width: 945px) {
  .footer__show-sm {
    display: block; }
  .footer-col--col1 {
    width: 31%; }
    .footer-col--col1 .footer__help-links {
      display: none; }
    .footer-col--col1 .copyright {
      display: none; }
  .footer-col--col2 {
    width: 31%; }
  .footer-col--col3 {
    width: 27%; }
    .footer-col--col3 .footer-col__right {
      display: none; }
  .footer-col--col4 {
    display: none; }
  .footer-col__left {
    order: 2;
    display: block; }
  .footer-col__right {
    order: 1; }
  .footer-col__right {
    text-align: left;
    padding-left: 0; }
    .footer-col__right .footer__show-md {
      display: none; }
  .footer__address,
  .footer__email,
  .footer__time,
  .footer__region {
    text-align: left; }
  .footer__bottom {
    display: flex;
    width: 100%;
    padding-top: 18px; }
  .footer__consultation-button {
    margin-bottom: 20px; }
  .footer__help-links {
    display: flex;
    width: 62%;
    margin: 0; }
    .footer__help-links li {
      width: 50%;
      margin: 0; }
  .footer__body {
    padding-bottom: 30px; } }

@media (max-width: 745px) {
  .footer {
    text-align: center; }
  .copyright {
    display: block;
    padding-top: 10px; }
  .footer__address,
  .footer__email,
  .footer__time,
  .footer__region {
    text-align: center; }
  .footer-nav-list {
    margin-bottom: 30px; }
  .footer-cols {
    flex-direction: column; }
  .footer-col {
    width: 100%;
    padding: 0; }
  .footer__phones {
    text-align: center; }
  .footer__time {
    margin-bottom: 20px; }
  .footer__bottom {
    display: block; }
    .footer__bottom .footer__help-links {
      width: 100%;
      display: block; }
      .footer__bottom .footer__help-links li {
        width: 100%;
        display: block;
        margin-bottom: 10px; } }

@media (max-width: 460px) {
  .footer__body {
    padding-bottom: 50px; } }

.content {
  padding-top: 60px;
  padding-bottom: 80px; }
  .content .section-title {
    padding-top: 0px;
    margin-bottom: 50px; }

.article {
  padding-bottom: 60px; }

.article__date {
  padding: 2px 7px;
  background: #F2F2F2;
  border-radius: 100px;
  font-size: 14px;
  color: #7E8692;
  letter-spacing: 0.08px;
  line-height: 18px;
  display: inline-block;
  margin-bottom: 20px; }

.articles + .articles {
  border-top: 1px solid #E2E5EA; }

.articles-list {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  flex-wrap: wrap; }

.swiper-wrapper .article-preview {
  box-sizing: border-box; }

.article-preview {
  width: calc(25% - 20px);
  margin: 0 10px;
  margin-bottom: 60px;
  font-size: 14px;
  line-height: 22px; }

.article-preview__image {
  border: 0;
  display: block;
  margin-bottom: 10px;
  height: 180px;
  font-size: 0;
  position: relative; }
  .article-preview__image:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: all 0.3s;
    background: rgba(255, 255, 255, 0.3);
    border: 3px solid rgba(255, 255, 255, 0.45);
    opacity: 0;
    z-index: 1;
    box-sizing: border-box; }
  .article-preview__image img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    font-family: "object-fit: cover; object-position: center"; }
  .article-preview__image:hover:before {
    opacity: 1; }

.article-preview__name {
  font-weight: bold;
  margin-bottom: 5px; }

.article-preview__date {
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 10px; }

.article-preview--img-left {
  position: relative;
  padding-left: 100px;
  min-height: 80px;
  width: 100%; }
  .article-preview--img-left .article-preview__image {
    position: absolute;
    width: 80px;
    height: 80px;
    left: 0;
    top: 0;
    padding: 0; }

.pagination {
  display: block;
  text-align: center;
  padding-top: 20px;
  list-style: none;
  font-size: 0;
  margin-bottom: 30px; }
  .pagination li {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 0;
    font-size: 14px;
    line-height: 1; }
    .pagination li span {
      display: block;
      width: 40px;
      height: 40px;
      line-height: 40px; }
    .pagination li:before {
      display: none; }
    .pagination li:last-child {
      margin-right: 0; }
    .pagination li a {
      display: block;
      font-size: 14px;
      border: 0;
      height: 40px;
      line-height: 40px;
      min-width: 40px;
      padding: 0 5px;
      color: #1F72CF;
      border-radius: 5px;
      border: 1px solid #fff; }
      .pagination li a:hover {
        border-color: #1F72CF;
        color: #1F72CF; }
    .pagination li.is-active a {
      color: #fff;
      background: #1F72CF;
      border-color: #1F72CF; }

.article__cover {
  max-width: 1180px;
  margin: 0 auto;
  height: 400px;
  position: relative;
  z-index: 0;
  margin-bottom: 30px;
  padding: 40px 0;
  display: flex;
  align-items: flex-end; }
  .article__cover .container {
    padding: 0; }
  .article__cover img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    font-family: "object-fit: cover; object-position: center"; }

.article__name {
  line-height: 1.15;
  max-width: 600px;
  margin: 0;
  margin-left: 200px;
  font-weight: 600; }
  .article__name span {
    background: #fff; }

.article__inner {
  max-width: 680px;
  margin-left: 200px; }

.read-more {
  padding-bottom: 100px; }
  .read-more .section-title {
    padding-top: 38px;
    margin-bottom: 26px;
    text-align: left; }

.read-more-slider:not(.slick-initialized) .sld:first-child, .works-slider:not(.slick-initialized) .sld:first-child {
  max-width: 380px; }

.read-more-slider {
  margin-bottom: 40px; }
  .read-more-slider .article-preview {
    margin: 0;
    width: 100%; }
  .read-more-slider .slick-prev {
    top: 38px;
    left: -35px; }
  .read-more-slider .slick-next {
    top: 38px;
    right: -35px; }

.read-more__button {
  text-align: center; }

@media (max-width: 1245px) {
  .content {
    padding-bottom: 70px; }
  .article-preview {
    width: calc(33.33% - 20px); }
  .article__inner {
    margin-left: 100px; }
  .article__name {
    margin-left: 120px; } }

@media (max-width: 945px) {
  .article-preview {
    width: calc(50% - 20px); }
  .article__inner {
    margin-left: 0px; }
  .article__name {
    margin-left: 0px; } }

@media (max-width: 745px) {
  .content {
    padding-top: 50px; }
    .content .section-title {
      margin-bottom: 30px;
      text-align: left; }
  .article-preview {
    width: 100%;
    margin-left: 0;
    margin-right: 0; }
    .article-preview:last-child {
      margin-bottom: 0; }
  .articles-list {
    margin-left: 0;
    margin-right: 0; }
  .article-preview__image {
    height: auto;
    padding-bottom: 64%; }
  .pagination {
    padding-top: 80px; }
    .pagination li {
      margin-right: 7px; }
  .article {
    padding-bottom: 40px; }
  .read-more {
    padding-bottom: 80px; }
  .article__name {
    font-size: 24px;
    line-height: 1.2; }
  .article__cover {
    padding: 20px 7px;
    height: auto;
    min-height: 245px; }
  .read-more-slider .article-preview {
    width: 260px; }
    .read-more-slider .article-preview .article-preview__descr {
      display: none; } }

.about-company {
  color: #fff;
  background: #0550a4 url(../img/about_company_bg.jpg) no-repeat center;
  background-size: cover;
  overflow: hidden;
  font-size: 14px;
  line-height: 22px;
  padding-bottom: 108px; }
  .about-company .section-title {
    ont-weight: 600;
    font-size: 39px;
    letter-spacing: 0.23px;
    line-height: 42px;
    margin-bottom: 10px; }
  .about-company .container {
    position: relative; }

.about-company__descr {
  max-width: 480px;
  margin-bottom: 46px; }
  .about-company__descr p:last-child {
    margin: 0; }

.about-company__features {
  display: flex;
  flex-wrap: wrap; }

.about-company__feature {
  width: 50%;
  margin-bottom: 42px;
  padding-right: 20px; }
  .about-company__feature h4, .about-company__feature .about-company__title {
    color: #fff;
    font-size: 14px;
    line-break: 22px;
    font-weight: bold;
    margin-bottom: 5px;
    position: relative;
    padding-top: 41px; }
    .about-company__feature h4:before, .about-company__feature .about-company__title:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: contain; }
  .about-company__feature p {
    margin: 0; }
  .about-company__feature--best h4:before, .about-company__feature--best .about-company__title:before {
    width: 29px;
    height: 29px;
    background-image: url(../img/about_company_feature1.svg);
    top: 4px; }
  .about-company__feature--speed h4:before, .about-company__feature--speed .about-company__title:before {
    width: 30px;
    height: 30px;
    background-image: url(../img/about_company_feature2.svg);
    top: 3px; }
  .about-company__feature--efficiency h4:before, .about-company__feature--efficiency .about-company__title:before {
    width: 33px;
    height: 33px;
    background-image: url(../img/about_company_feature3.svg); }
  .about-company__feature--price h4:before, .about-company__feature--price .about-company__title:before {
    width: 32px;
    height: 32px;
    background-image: url(../img/about_company_feature4.svg);
    top: 1px; }

.about-company__text {
  width: calc(50% + 20px); }

.about-company__image {
  width: 100%;
  max-width: 650px;
  position: absolute;
  background-size: contain;
  top: 50%;
  transform: translateY(-47%);
  right: -50px; }

.b-contacts {
  padding-bottom: 50px;
  position: relative;
  font-size: 14px;
  line-height: 22px; }
  .b-contacts .section-title {
    margin-bottom: 37px; }

.contacts-item {
  margin-bottom: 30px; }
  .contacts-item h4, .contacts-item .contacts-item__title {
    font-size: 14px;
    line-height: 22px;
    color: #1E1F21;
    font-weight: bold;
    margin-bottom: 4px; }
  .contacts-item address {
    font-style: normal; }
  .contacts-item address, .contacts-item p {
    margin-bottom: 3px;
    display: block; }
    .contacts-item address:last-child, .contacts-item p:last-child {
      margin: 0; }

.contacts-item__link {
  text-transform: uppercase;
  border: 0;
  font-size: 11px;
  line-height: 14px;
  font-weight: 600;
  display: inline-block;
  border-bottom: 1px dashed rgba(31, 114, 207, 0.5); }
  .contacts-item__link:hover {
    border-bottom-color: rgba(0, 77, 163, 0.4); }

.contacts-item__phone,
.contacts-item__email {
  color: #1E1F21;
  border: 0; }
  .contacts-item__phone:hover,
  .contacts-item__email:hover {
    color: #004DA3; }

#contacts-map {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  width: calc((100vw - 1180px) / 2 + 800px); }

@media (max-width: 1245px) {
  .about-company {
    padding-bottom: 30px;
    background-position: 30% center; }
  .about-company__text {
    width: 45%;
    max-width: 380px; }
  .about-company__image {
    max-width: 530px;
    right: -60px;
    transform: translateY(-28%); }
  .about-company__descr {
    margin-bottom: 22px; }
  .about-company__features {
    display: block; }
  .about-company__feature {
    width: 100%;
    padding: 0;
    margin-bottom: 40px; }
  #contacts-map {
    width: calc((100vw - 900px) / 2 + 580px); } }

@media (max-width: 945px) {
  .about-company {
    background-position: center;
    padding-bottom: 30px; }
    .about-company .section-title {
      padding-top: 23px; }
  .about-company__descr {
    max-width: 100%; }
  .about-company__text {
    width: 100%;
    max-width: 100%; }
  .about-company__features {
    display: flex;
    margin-bottom: -25px; }
  .about-company__feature {
    width: 50%;
    padding-right: 20px; }
  .about-company__image {
    position: relative;
    transform: translate(0);
    top: 0;
    right: 0;
    margin: 0;
    max-width: 414px;
    margin: 0 auto; }
  .b-contacts {
    padding-bottom: 0; }
    .b-contacts .section-title {
      padding-top: 50px; }
  #contacts-map {
    position: relative;
    width: 100%;
    height: 470px; }
  .contacts-items {
    padding-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    max-width: 600px; }
  .contacts-item {
    width: 33.33%;
    padding-right: 20px; } }

@media (max-width: 745px) {
  .about-company {
    padding-bottom: 60px; }
    .about-company .section-title {
      padding-top: 50px;
      font-size: 28px;
      letter-spacing: 0.16px;
      line-height: 32px; }
  .about-company__features {
    margin-bottom: 0; }
  .about-company__feature {
    width: 100%;
    padding-right: 0; }
    .about-company__feature:last-child {
      margin-bottom: 25px; }
  .b-contacts .section-title {
    padding-top: 60px;
    margin-bottom: 30px; }
  .contacts-item {
    padding: 0;
    width: 100%; }
  .contacts-items {
    padding-bottom: 30px; } }

.not-found {
  padding-top: 142px;
  padding-bottom: 254px;
  background-image: url(../img/404.jpg);
  background-color: #000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center;
  font-size: 0;
  flex: 1; }
  .not-found h3 {
    font-weight: 500;
    font-size: 23px;
    letter-spacing: 0.13px;
    line-height: 44px;
    margin: 0;
    margin-bottom: -16px; }
  .not-found h5 {
    opacity: 0.4;
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 0.1px;
    line-height: 44px;
    margin-bottom: 30px; }

.not-found__num {
  font-weight: 200;
  font-size: 156px;
  letter-spacing: 0.91px;
  line-height: 1;
  margin-bottom: -15px; }

@media (max-width: 945px) {
  .not-found {
    padding-top: 112px;
    padding-bottom: 168px;
    background-image: url(../img/404_sm.jpg); } }

@media (max-width: 745px) {
  .not-found {
    padding-top: 54px;
    padding-bottom: 221px;
    background-image: url(../img/404_xs.jpg); }
    .not-found h3 {
      font-size: 16px;
      letter-spacing: 0.09px;
      margin-bottom: 3px;
      line-height: 20px; }
    .not-found h5 {
      font-size: 15px;
      line-height: 19px; }
  .not-found__num {
    font-size: 97px;
    margin-bottom: 0; } }

.my-account {
  flex: 1;
  display: flex;
  flex-direction: column; }
  .my-account .container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 0;
    flex: 1; }
    .my-account .container:after {
      content: "";
      position: absolute; }

.my-account__background {
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: calc(34% + 6px);
  background: #eef2f5;
  min-height: 450px;
  z-index: -1; }
  .my-account__background:before {
    content: "";
    left: 46px;
    bottom: 0;
    width: 734px;
    height: 351px;
    background-image: url("../img/my_account_illustration.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 144dpi) {
      .my-account__background:before {
        background-image: url("../img/my_account_illustration@2x.png"); } }

.my-account__left {
  width: 34%;
  padding: 73px 0;
  padding-right: 30px; }
  .my-account__left h5 {
    font-weight: 500;
    font-size: 16px;
    color: #1E1F21;
    letter-spacing: 0.09px;
    line-height: 20px;
    margin-bottom: 15px; }

.my-account__right {
  width: 66%;
  min-height: 450px;
  padding-top: 73px;
  padding-left: 102px;
  padding-right: 76px;
  padding-bottom: 350px; }

.my-account__heading {
  margin-bottom: 30px;
  font-size: 32px;
  letter-spacing: 0.19px;
  line-height: 40px; }

.login-form {
  max-width: 280px;
  margin-bottom: 60px; }
  .login-form .form-field-container {
    margin-bottom: 10px; }

.login-form__button {
  line-height: 1;
  padding-top: 10px;
  text-align: right; }

.my-account__info p {
  margin-bottom: 8px;
  opacity: 0.7;
  font-size: 13px;
  color: #1E1F21;
  letter-spacing: 0.08px;
  line-height: 20px;
  font-weight: 300; }

.my-account__phone {
  font-weight: 500;
  font-size: 16px;
  color: #004DA3;
  letter-spacing: 0.09px;
  line-height: 20px;
  border: 0; }
  .my-account__phone:hover {
    color: #1E1F21; }

.my-account__benefits {
  display: flex;
  flex-wrap: wrap; }
  .my-account__benefits li {
    padding: 0;
    padding-top: 30px;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.08px;
    line-height: 19px;
    margin-bottom: 32px;
    width: 50%;
    padding-right: 20px; }
    .my-account__benefits li:before {
      display: none; }
    .my-account__benefits li:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 22px;
      height: 22px;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: contain; }
  .my-account__benefits p {
    margin: 0;
    max-width: 200px; }

.my-account__benefit--i1:after {
  background-image: url(../img/my_account_benefit1.svg); }

.my-account__benefit--i2:after {
  background-image: url(../img/my_account_benefit2.svg); }

.my-account__benefit--i3:after {
  background-image: url(../img/my_account_benefit3.svg);
  height: 20px; }

.my-account__benefit--i4:after {
  background-image: url(../img/my_account_benefit4.svg); }

.logout {
  font-size: 0;
  margin-bottom: 20px; }
  .logout .btn {
    margin-right: 20px; }
  .logout a {
    display: inline-block;
    vertical-align: middle; }
  .logout a:not(.btn) {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.08px;
    line-height: 22px;
    margin: 10px 0;
    display: inline-block;
    line-height: 1.1; }

.modal--item .item-modal__name {
  font-size: 39px;
  line-height: .92307692;
  letter-spacing: .23px;
  font-weight: 600; }

.credit_submit_btn {
  margin-top: 10px; }

#credit_popup .form__heading .form_title {
  margin-bottom: 20px;
  font-size: 30px; }

#credit_popup .modal-form .form__heading p {
  max-width: 100%; }

#credit_popup .form__note--credit {
  margin-top: 15px; }

#credit_popup .form__note--credit a {
  color: #337ab7;
  border-bottom-color: #6fa1cc;
  border-bottom-style: dashed; }

#credit_popup .form {
  max-width: 100%; }

#credit_popup span.credit_price {
  color: #3977bc;
  white-space: nowrap; }

.b-credit-info {
  margin: 40px 0 60px; }

.b-credit-info .main_img {
  max-width: 100%;
  margin: 20px 0; }

.form-request.form-credit__inline .form-field {
  border-color: #dfdfe0; }

.form-credit__inline .radio--simple label span:before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0;
  top: 3px;
  border: 1px solid #3f89dc;
  border-radius: 6px;
  transition: all 0.3s; }

.form-credit__inline .radio--simple label input:checked ~ span:before {
  background: #3f89dc; }

.form-credit__inline .radio--simple label:hover span:before {
  box-shadow: 0 0 0 4px rgba(81, 176, 242, 0.2); }

.form-credit__inline .radio--simple label input:checked ~ span:after {
  opacity: 1; }

.form-credit__inline .radio--simple label span:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 8px;
  background: url("/local/templates/altclick_new/static/dist/img/checkbox.svg") no-repeat 0 0;
  background-size: contain;
  top: 7px;
  left: 4px;
  transition: all 0.3s;
  opacity: 0; }

.form-credit__inline .radio--simple label {
  display: inline-block;
  margin-right: 10px;
  position: relative; }

.form-credit__inline .radio--simple label {
  padding-left: 27px; }

.form-credit__inline .radio_block_title {
  margin-bottom: 10px;
  font-weight: 600; }

.form-request.form-credit__inline .form__heading {
  margin-bottom: 30px; }

.credit_form_block {
  margin: 60px 0 40px; }

.form-credit__inline .constructor-item__button input:checked ~ span {
  background: #3f89dc;
  color: #fff; }

.form-credit__inline .radio--fill label {
  padding-left: 0; }

@media (max-width: 1245px) {
  .my-account__background:before {
    left: 35px;
    bottom: 0;
    width: 634px;
    height: 303px; }
  .my-account__right {
    padding-bottom: 300px; } }

@media (max-width: 945px) {
  .my-account__left {
    width: 44%; }
  .my-account__right {
    width: 56%;
    padding-right: 0;
    padding-bottom: 210px; }
  .my-account__background {
    left: 44.25%;
    overflow: auto; }
    .my-account__background:before {
      left: 20px;
      width: 427px;
      height: 204px; }
  .my-account__benefits li {
    width: 100%;
    padding-right: 0; } }

@media (max-width: 745px) {
  .my-account__left {
    width: 100%;
    padding: 40px 0; }
  .my-account__right {
    width: calc(100% + 40px);
    padding: 0;
    padding: 40px 42px;
    background: #eef2f5;
    margin-left: -20px;
    margin-right: -20px;
    position: relative;
    padding-bottom: 170px; }
    .my-account__right:before {
      content: "";
      left: 46px;
      bottom: 0;
      width: 337px;
      height: 161px;
      background: url(../img/my_account_illustration.svg) no-repeat 0 0;
      background-size: contain;
      position: absolute;
      left: 50%;
      margin-left: -168px;
      bottom: 0; }
  .my-account__heading {
    font-size: 22px;
    letter-spacing: 0.13px;
    line-height: 28px;
    margin-bottom: 20px; }
  .login-form {
    margin-bottom: 40px;
    max-width: 100%; }
  .my-account__background {
    display: none; } }

.seo_text_4 {
  margin-bottom: 40px; }

.seo_text_5 {
  margin-top: 40px; }

.icon-item {
  padding-left: 75px !important;
  position: relative;
  min-height: 60px; }
  .icon-item:before {
    content: "";
    width: 50px;
    height: 50px;
    position: absolute;
    left: 13px;
    top: 0; }
  .icon-item--wifi:before {
    background: url("/local/templates/altclick_new/static/dist/img/007-wifi.png") no-repeat;
    background-size: contain; }
  .icon-item--phone:before {
    background: url("/local/templates/altclick_new/static/dist/img/004-customer-service.png") no-repeat;
    background-size: contain; }
  .icon-item--pause:before {
    background: url("/local/templates/altclick_new/static/dist/img/005-pause.png") no-repeat;
    background-size: contain; }
  .icon-item--speed:before {
    background: url("/local/templates/altclick_new/static/dist/img/003-speed.png") no-repeat;
    background-size: contain; }
  .icon-item--search:before {
    background: url("/local/templates/altclick_new/static/dist/img/008-loupe.png") no-repeat;
    background-size: contain; }
  .icon-item--tech:before {
    background: url("/local/templates/altclick_new/static/dist/img/006-customer-support.png") no-repeat;
    background-size: contain; }

.hidden_region_block {
  display: none; }

.show_more_block {
  margin-top: 20px; }

.show-all-regions {
  margin-top: 10px;
  margin-bottom: 10px; }

.b-region_text {
  margin-top: 40px;
  margin-bottom: 40px; }
  .b-region_text h1 {
    font-weight: 600;
    font-size: 30px;
    letter-spacing: .22px;
    text-align: left;
    line-height: 44px;
    padding-bottom: 40px;
    color: #1e1f21; }

.b-region_items {
  margin-top: 60px;
  padding-top: 40px;
  padding-bottom: 40px;
  background: #f1f4f8; }

h1 .blue {
  color: #3977bc; }

.tariffs-page ul li:before {
  top: 12px; }

.tariffs-page .b-guarantee__right .section-title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px; }

.tariffs-page .place-illustration {
  height: 150px;
  margin-left: 37px;
  display: block !important;
  margin-top: 20px;
  width: 100%;
  max-width: 100%; }
  .tariffs-page .place-illustration img {
    margin-top: 10px; }

.tariffs-page .places__illustrations {
  width: 100%;
  max-width: 100%;
  position: relative;
  height: 160px;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important; }

.tariffs-page .b-gift_text {
  background: #f1f4f8; }

.tariffs-page .b-gift_text, .tariffs-page .b-how_choose {
  padding-bottom: 60px; }

.tariffs-page .b-guarantee {
  margin-bottom: 60px; }

.tariffs-page .center-blue {
  color: #3977bc;
  text-align: center;
  margin: 0 auto;
  max-width: 620px;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px; }

.tariffs-page .b-map {
  margin-bottom: 60px; }

.b-tariffs {
  margin-bottom: 60px; }
  .b-tariffs .tariffs-item {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 20px 25px;
    margin-bottom: 20px; }
    .b-tariffs .tariffs-item .tariff-title {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0; }
    .b-tariffs .tariffs-item .image_block {
      text-align: center; }
      .b-tariffs .tariffs-item .image_block img {
        max-height: 80px;
        margin: 10px; }
    .b-tariffs .tariffs-item .tariff-price {
      text-align: center;
      display: block;
      font-size: 22px;
      margin-top: 0;
      font-weight: 600;
      margin-bottom: 0; }
    .b-tariffs .tariffs-item .price_descr {
      border-bottom: 1px dashed;
      display: inline-block;
      cursor: pointer; }
    .b-tariffs .tariffs-item .price_descr_wrapper {
      text-align: center;
      margin: -10px; }
  .b-tariffs .tariff-info {
    margin: 20px 0 30px; }
  .b-tariffs .tariff-info-item {
    padding-left: 50px !important;
    position: relative; }
    .b-tariffs .tariff-info-item .title {
      margin-bottom: 0;
      color: #848080;
      font-size: 15px; }
    .b-tariffs .tariff-info-item .text {
      line-height: 18px; }
    .b-tariffs .tariff-info-item:before {
      content: "";
      width: 30px;
      height: 30px;
      position: absolute;
      left: 3px;
      top: 0; }
    .b-tariffs .tariff-info-item--rubl:before {
      background: url("/local/templates/altclick_new/static/dist/img/002-ruble.svg") no-repeat;
      background-size: contain;
      top: 4px; }
    .b-tariffs .tariff-info-item--infinity:before {
      background: url("/local/templates/altclick_new/static/dist/img/001-infinite-symbol.svg") no-repeat;
      background-size: contain; }
    .b-tariffs .tariff-info-item--speed {
      height: 60px; }
      .b-tariffs .tariff-info-item--speed:before {
        background: url("/local/templates/altclick_new/static/dist/img/003-speedometer.svg") no-repeat;
        background-size: contain; }
  .b-tariffs .tarrif-btn-block {
    text-align: center; }
    .b-tariffs .tarrif-btn-block .btn {
      min-width: 155px;
      padding: 15px;
      color: white !important; }
  .b-tariffs .tariff-link-block {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    text-align: center;
    padding: 10px 0 0; }
    .b-tariffs .tariff-link-block a {
      color: #00C767;
      border-bottom: 1px dashed; }
      .b-tariffs .tariff-link-block a:hover {
        opacity: 0.75; }
  .b-tariffs .advantages-items {
    max-width: 400px; }
  .b-tariffs .advantages-item {
    padding-left: 60px !important;
    position: relative; }
    .b-tariffs .advantages-item:before {
      content: "";
      width: 38px;
      height: 38px;
      position: absolute;
      left: 3px;
      top: 0;
      background: url("/local/templates/altclick_new/static/dist/img/004-accept.svg") no-repeat;
      background-size: contain; }
    .b-tariffs .advantages-item .title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0; }
  .b-tariffs .tariffs-items {
    margin-bottom: 60px; }
  .b-tariffs .tariffs_form {
    background-color: #f2f2f2;
    padding: 15px 20px; }
    .b-tariffs .tariffs_form .btn {
      width: auto; }
    .b-tariffs .tariffs_form .form__note {
      text-align: left;
      margin: 0; }
    .b-tariffs .tariffs_form .form-request .form__body {
      margin: 0;
      width: 100%;
      max-width: 100%; }
    .b-tariffs .tariffs_form .form-request .form__heading {
      margin-bottom: 20px; }
      .b-tariffs .tariffs_form .form-request .form__heading p {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0; }
    .b-tariffs .tariffs_form .form-request .form__button {
      padding-top: 0;
      text-align: left; }

.blue {
  color: #3977bc; }

.override-blue span {
  color: black !important; }

@media (min-width: 1200px) and (max-width: 1246px) {
  .tariffs-item-wrapper {
    width: 50% !important; } }

@media (max-width: 766px) {
  .tariffs-page .place-illustration {
    margin-left: 0; }
    .tariffs-page .place-illustration ul {
      margin-top: 20px; }
  .tariffs-page .places__illustrations {
    height: 320px; } }

@media (max-width: 500px) {
  .tariffs-page .places__illustrations {
    height: 360px; } }

@media (max-width: 420px) {
  .tariffs-page .places__illustrations {
    height: 420px; } }

#callback-sim .form__heading .form_title {
  font-size: 36px;
  max-width: 100%; }

@media (max-width: 745px) {
  #callback-sim .form__heading .form_title {
    font-size: 28px; } }

.b-steps .section-title {
  text-align: left;
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 39px;
  letter-spacing: .23px;
  line-height: 36px; }

.b-steps .steps-subtitle {
  font-size: 20px;
  font-weight: 600; }

.b-steps .steps__items {
  margin-top: 30px; }

.b-steps .step-item-wrapper {
  margin-bottom: 20px; }

.b-steps .step {
  background: #00C767;
  padding: 10px;
  height: 264px;
  position: relative; }
  .b-steps .step:after {
    content: "";
    background: url("../img/step-arrow.svg") no-repeat 0 0;
    position: absolute;
    z-index: 1;
    width: 60px;
    height: 264px;
    top: 0;
    right: -30px; }
  .b-steps .step:before {
    content: "";
    position: absolute;
    bottom: -2px;
    top: initial;
    right: 31px;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
    z-index: 2; }
  .b-steps .step--step1:before {
    background-image: url("../img/step1.svg");
    background-size: contain;
    bottom: -15px; }
  .b-steps .step--step2:before {
    background-image: url("../img/step2.svg");
    background-size: contain;
    bottom: 0; }
  .b-steps .step--step3:before {
    background-image: url("../img/step3.svg");
    background-size: contain; }
  .b-steps .step--step4:before {
    background-image: url("../img/step4.svg");
    background-size: contain;
    right: 10px; }

.b-steps .step-item-wrapper:last-child .step:after {
  background: none; }

.b-steps .step-number {
  font-size: 58px;
  color: #008947;
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 58px; }

.b-steps .step-title {
  color: #FFFFFF;
  font-weight: 700;
  font-size: 20px;
  line-height: 21px;
  margin-bottom: 10px; }

.b-steps .step-text {
  color: #FFFFFF;
  font-size: 13px;
  line-height: 19px;
  font-weight: 600;
  margin-bottom: 10px;
  position: relative;
  z-index: 3; }

.b-steps .btn--white {
  color: #00c767;
  position: relative;
  z-index: 3; }

.b-steps .btn--white:hover {
  color: #00c767;
  box-shadow: inset 0 0 0 3px #00c7676b, 0 5px 5px rgba(0, 0, 0, 0.18); }

.b-steps .step-btn-block {
  margin-bottom: 10px; }

@media (min-width: 1200px) and (max-width: 1246px) {
  .b-steps .step-item-wrapper {
    width: 50% !important; } }

@media (max-width: 1246px) {
  .b-steps .step:after {
    background: none; }
  .b-steps .step {
    background: #00c767;
    padding: 10px;
    height: 240px;
    position: relative; } }

@media (max-width: 766px) {
  .b-steps .step {
    height: auto; } }

.suggestions-promo {
  display: none !important; }

.fancybox-close-small svg path {
  fill: #b2b2b2; }

.get-fast-speed {
  overflow: hidden; }

.b-why--faq .b-why__good {
  padding-bottom: 60px; }
  .b-why--faq .b-why__good:before {
    width: 430px;
    height: 242px;
    bottom: 20px;
    right: 10px;
    display: block; }

.b-why--faq .why-features {
  padding: 0 0 10px; }

.b-why--faq .why-features__list {
  display: flex;
  flex-wrap: wrap;
  width: auto; }
  .b-why--faq .why-features__list > li {
    margin-right: 40px; }

@media (max-width: 1245px) {
  .b-why--faq .b-why__good:before {
    display: block;
    left: auto;
    right: 10px;
    width: 263px;
    height: 148px;
    bottom: 0; } }

@media (max-width: 745px) {
  .b-why--faq .b-why__good {
    padding-bottom: 200px; }
    .b-why--faq .b-why__good:before {
      display: block;
      width: 263px;
      height: 148px;
      bottom: 32px;
      left: 14px;
      right: auto; } }

.b-why__faq {
  margin: 0 -10px 35px;
  display: flex;
  flex-wrap: wrap; }
  .b-why__faq .b-why__dl {
    margin: 0; }
    .b-why__faq .b-why__dl dt {
      border-radius: 5px; }
      .b-why__faq .b-why__dl dt:after {
        display: none; }

.b-why__faq-col {
  padding: 0 10px;
  flex: 0 0 50%;
  max-width: 50%; }
  @media (max-width: 945px) {
    .b-why__faq-col {
      flex: 0 0 100%;
      max-width: 100%; } }

.b-reasons {
  padding: 0 0 55px; }
  .b-reasons .section-title {
    margin: 0 0 50px; }
    .b-reasons .section-title img {
      vertical-align: baseline; }

.b-reasons__list {
  margin: 0 -10px;
  padding: 0;
  list-style: none;
  counter-reset: point;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  display: flex;
  flex-wrap: wrap; }
  .b-reasons__list > li {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding: 0 10px 0 72px;
    margin: 0 0 35px;
    flex: 0 0 33.333%;
    max-width: 33.333%; }
    .b-reasons__list > li:before {
      content: none; }
    .b-reasons__list > li:after {
      content: counter(point);
      counter-increment: point 1;
      position: absolute;
      top: 0;
      left: 10px;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid #004DA3;
      font-size: 24px;
      line-height: 1;
      font-weight: 700;
      color: #004DA3; }
  .b-reasons__list h3, .b-reasons__list strong {
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    margin: 0 0 15px; }
  .b-reasons__list p {
    margin: 0; }
  @media (max-width: 945px) {
    .b-reasons__list > li {
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (max-width: 745px) {
    .b-reasons__list > li {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%; } }

.section-text {
  display: block;
  font-size: 14px;
  line-height: 22px;
  margin: 0 0 30px; }

.b-connect {
  background: #F4F6F9;
  padding: 70px 0; }
  .b-connect .section-title {
    margin: 0 0 30px;
    padding: 0; }

.b-connect__row {
  display: flex;
  flex-wrap: wrap; }

.b-connect__left {
  width: 54.5%;
  max-width: 54.5%;
  position: relative; }
  @media (max-width: 945px) {
    .b-connect__left {
      width: 100%;
      max-width: 100%; } }

.b-connect__right {
  width: 45.5%;
  max-width: 45.5%;
  padding: 40px 0 0; }
  @media (max-width: 945px) {
    .b-connect__right {
      width: 100%;
      max-width: 100%; } }

.b-connect__image {
  position: relative;
  margin: -30px 0 -120px; }
  .b-connect__image img {
    display: block; }
  @media (max-width: 945px) {
    .b-connect__image {
      margin: 0; } }

.b-connect__list {
  margin: 0 0 0 30px;
  padding: 0;
  list-style: none;
  counter-reset: point;
  font-size: 14px;
  line-height: 22px; }
  .b-connect__list > li {
    position: relative;
    padding: 0 0 0 62px;
    margin: 0 0 25px; }
    .b-connect__list > li:last-child {
      margin: 0; }
    .b-connect__list > li:before {
      content: none; }
    .b-connect__list > li:after {
      content: counter(point);
      counter-increment: point 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #004DA3;
      font-size: 24px;
      line-height: 1;
      font-weight: 700;
      color: #fff; }
  .b-connect__list h3 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    padding: 10px 0;
    margin: 0 0 5px; }
  .b-connect__list p {
    margin: 0; }

.section-inform {
  display: block;
  font-size: 15px;
  line-height: 22px;
  margin: 0 0 50px; }

.section-inform__question {
  display: block;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
  margin: 0 0 10px; }
  .section-inform__question .tooltip-info {
    margin: 0 5px 0 0; }

.section-inform__answer {
  margin: 0; }

.b-steps--blue {
  margin: 0 0 80px; }
  .b-steps--blue .step {
    background: #1F72CF; }
    .b-steps--blue .step:after {
      background-image: url(../img/step-arrow-blue.svg); }
  .b-steps--blue .step-number {
    color: #004DA3; }
  .b-steps--blue .step--step1:before {
    background-image: url(../img/step1-blue.svg); }
  .b-steps--blue .step--step2:before {
    background-image: url(../img/step2-blue.svg); }
  .b-steps--blue .step--step3:before {
    background-image: url(../img/step3-blue.svg); }
  .b-steps--blue .step--step4:before {
    background-image: url(../img/step4-blue.svg); }
  @media (max-width: 1246px) {
    .b-steps--blue .step:after {
      background: none; } }

.speed-features--w700 {
  max-width: 580px; }
  .speed-features--w700 .btn--white {
    color: #00c767; }
    .speed-features--w700 .btn--white:hover {
      color: #00c767;
      box-shadow: inset 0 0 0 3px #00c7676b, 0 5px 5px rgba(0, 0, 0, 0.18); }
  @media (max-width: 945px) {
    .speed-features--w700 {
      max-width: none; } }

.speed-features__item--test:before {
  background-image: url(../img/speed_feature_test.svg);
  width: 22px;
  height: 20px; }

.speed-features__item--shield:before {
  background-image: url(../img/speed_feature_shield.svg);
  width: 18px;
  height: 22px; }

.speed-features__item--sim:before {
  background-image: url(../img/speed_feature_sim.svg);
  width: 18px;
  height: 22px; }

.b-benefits {
  background: #F4F6F9;
  padding: 87px 0 94px; }

.b-benefits__list {
  margin: 0 -30px -60px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap; }
  .b-benefits__list > li {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    padding: 0 30px 60px; }
    .b-benefits__list > li:before {
      display: none; }
  @media (max-width: 945px) {
    .b-benefits__list > li {
      flex: 0 0 100%;
      max-width: 100%;
      text-align: center; } }

.b-benefits__title {
  display: flex;
  align-items: center;
  font-size: 39px;
  line-height: 1;
  font-weight: 700;
  color: #1F72CF;
  margin: 0 0 30px; }
  .b-benefits__title img {
    display: block;
    flex: 0 0 auto;
    margin: 0 20px 0 0; }
  @media (max-width: 1245px) {
    .b-benefits__title {
      font-size: 28px; } }
  @media (max-width: 945px) {
    .b-benefits__title {
      justify-content: center; } }

.b-benefits__text {
  margin: 0;
  font-size: 14px;
  line-height: 22px; }
  @media (max-width: 945px) {
    .b-benefits__text {
      max-width: 480px;
      display: block;
      margin: 0 auto; } }

.b-team {
  padding: 85px 0 95px; }
  .b-team .section-title {
    margin: 0 0 45px; }

.b-team__list {
  margin: 0 -10px -20px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .b-team__list > li {
    padding: 0 10px 20px;
    flex: 0 0 16.666%;
    max-width: 16.666%; }
    .b-team__list > li:before {
      display: none; }
  @media (max-width: 1245px) {
    .b-team__list > li {
      flex: 0 0 33.333%;
      max-width: 33.333%; } }
  @media (max-width: 745px) {
    .b-team__list > li {
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (max-width: 480px) {
    .b-team__list > li {
      flex: 0 0 100%;
      max-width: 100%; } }

.b-team__item {
  max-width: 180px;
  margin: 0 auto;
  text-align: center; }

.b-team__image {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  background: #ccc;
  margin: 0 0 20px; }
  .b-team__image:before {
    content: '';
    float: left;
    padding: 100% 0 0; }
  .b-team__image img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.b-team__title {
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px; }
  .b-team__title small {
    display: block;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    padding: 5px 0 0; }

.section-button {
  text-align: center;
  margin: 0 0 40px; }

.reviews {
  padding: 86px 0 100px; }
  .reviews .section-title {
    padding: 0;
    margin: 0 0 30px; }

.reviews__control {
  text-align: center; }

.reviews__grid {
  margin: 0 -10px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap; }
  .reviews__grid > li {
    padding: 0 10px 40px;
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%; }
    .reviews__grid > li:before {
      display: none; }
    .reviews__grid > li:nth-of-type(6) ~ li {
      display: none; }
  @media (max-width: 1245px) {
    .reviews__grid > li {
      width: 33.333%;
      flex: 0 0 33.333%;
      max-width: 33.333%; } }
  @media (max-width: 945px) {
    .reviews__grid > li {
      width: 50%;
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (max-width: 745px) {
    .reviews__grid > li {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%; } }

.is-open .reviews__grid > li:nth-of-type(6) ~ li {
  display: block; }

.reviews__item {
  position: relative;
  padding: 30px;
  background: #F4F6F9;
  border-radius: 5px; }
  .reviews__item:after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: 40px;
    border-right: 30px solid transparent;
    border-top: 20px solid #F4F6F9; }
  .reviews__item [itemprop="ratingValue"],
  .reviews__item [itemprop="itemreviewed"] {
    position: absolute;
    visibility: hidden; }

.reviews__item-rating {
  display: block;
  margin: 0 0 15px;
  display: flex;
  align-items: center; }
  .reviews__item-rating svg {
    width: 22px;
    height: 22px;
    margin: 0 4px 0 0;
    color: #1F72CF; }
  .reviews__item-rating[data-rating="4"] svg:nth-of-type(4) ~ svg {
    color: transparent; }
  .reviews__item-rating[data-rating="3"] svg:nth-of-type(3) ~ svg {
    color: transparent; }
  .reviews__item-rating[data-rating="2"] svg:nth-of-type(2) ~ svg {
    color: transparent; }
  .reviews__item-rating[data-rating="1"] svg:nth-of-type(1) ~ svg {
    color: transparent; }
  .reviews__item-rating[data-rating="0"] svg {
    color: transparent; }

.reviews__item-content {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400; }
  .reviews__item-content figure {
    height: 155px;
    margin: 0 0 16px;
    background: #fff; }
    .reviews__item-content figure img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .reviews__item-content p {
    margin: 0 0 10px; }
  .reviews__item-content cite {
    display: block;
    font-weight: 500;
    font-style: normal; }
  .reviews__item-content time {
    font-size: 11px;
    opacity: 0.5; }

.b-stock {
  padding: 93px 0 100px;
  border-top: 1px solid #E2E5EA; }
  .b-stock .section-title {
    margin: 0 0 36px;
    padding: 0; }

.b-stock__list {
  margin: 0 -10px -20px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap; }
  .b-stock__list > li {
    padding: 0 10px 20px;
    margin: 0;
    flex: 0 0 50%;
    max-width: 50%; }
    .b-stock__list > li:before {
      display: none; }
  @media (max-width: 945px) {
    .b-stock__list > li {
      flex: 0 0 100%;
      max-width: 100%; } }

.b-stock__item {
  position: relative;
  z-index: 1;
  border-radius: 4px;
  background: #ccc;
  color: #fff;
  padding: 32px 40% 40px 40px;
  min-height: 100%; }
  .b-stock__item--green {
    background: #00C767; }
  .b-stock__item--blue {
    background: #1F72CF; }
  .b-stock__item .btn--white {
    color: #00C767; }
    .b-stock__item .btn--white:hover {
      color: #00c767;
      box-shadow: inset 0 0 0 3px #00c7676b, 0 5px 5px rgba(0, 0, 0, 0.18); }
  @media (max-width: 745px) {
    .b-stock__item {
      padding-right: 30%;
      padding: 30px; } }

.b-stock__item-image {
  width: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1; }

.b-stock__item-title {
  display: block;
  font-size: 20px;
  line-height: 22px;
  font-weight: 700;
  margin: 0 0 10px; }

.b-stock__item-text {
  font-size: 13px;
  line-height: 19px;
  font-weight: 600;
  margin: 0 0 16px;
  max-width: 240px; }

@media (max-width: 1245px) {
  .developer {
    margin: 0 0 30px;
    padding: 30px 0 0; } }

@media (max-width: 745px) {
  .developer {
    text-align: center; } }

.add-review .form__body {
  max-width: none; }

.add-review .form__body textarea {
  height: 150px;
  width: 100%;
  resize: none; }

.add-review .form-field-container--textarea {
  position: relative; }
  .add-review .form-field-container--textarea .form-placeholder {
    top: 25px; }

.add-review .form-placeholder {
  z-index: initial; }

.add-review-rating .reviews__item-rating svg {
  cursor: pointer; }

.oxem-captcha.has-errors iframe {
  box-shadow: 0 0 10px red;
  border-radius: 4px;
  width: 302px;
  height: 76px; }

@media (max-width: 786px) {
  .button_1O {
    margin-bottom: 80px !important; }
  .marquiz__body-modal_open .roistat-lh-pulsator-phone, .marquiz__body-modal_open jdiv {
    display: none !important; } }

.modal--work.fancybox-content {
  display: inline-block !important; }

.place-items-block {
  margin-top: 60px; }
  .place-items-block .place-item-title {
    font-weight: 500;
    margin: 10px 0 5px;
    line-height: 18px; }
    .place-items-block .place-item-title a {
      color: inherit;
      border: none; }
  .place-items-block .place-item-descr {
    font-size: 14px;
    line-height: 16px; }

@media (max-width: 480px) {
  .place-item {
    width: 100% !important;
    text-align: center; } }

.modal {
  display: none;
  width: 100%;
  max-width: 1180px;
  background: #fff;
  padding: 60px;
  border-radius: 5px;
  margin-left: -30px;
  font-size: 16px;
  font-family: "Avenir Next Cyr", "Helvetica Neue", -apple-system, BlinkMacSystemFont, sans-serif; }

.modal--item {
  padding-left: 100px;
  padding-right: 100px; }

.fancybox-slide {
  font-size: 0; }

.fancybox-bg {
  background: #000; }

.fancybox-is-open .fancybox-bg {
  opacity: 0.75; }

.fancybox-slide--html {
  padding: 0; }

.fancybox-slide--html .fancybox-content {
  margin: 0;
  margin-left: -30px; }

.fancybox-close-small {
  right: -50px;
  top: -13px;
  color: #fff;
  padding: 0;
  transition: all 0.3s; }

.fancybox-close-small svg {
  width: 43px;
  height: 43px;
  opacity: 1; }

.fancybox-content {
  overflow: initial; }

.fancybox-slide {
  overflow-x: hidden;
  overflow-y: auto; }

.item-modal {
  display: flex;
  padding-top: 10px; }

.item-modal__left {
  width: 42.25%;
  max-width: 42.25%;
  padding-right: 20px;
  margin-top: -50px; }
  .item-modal__left .item-modal__controls,
  .item-modal__left .item-modal__name {
    display: none; }

.item-modal__right {
  width: 57.75%;
  max-width: 57.75%; }

.item-modal__images {
  width: 100%;
  max-width: 378px;
  position: relative; }

.item-modal__images-full {
  height: 353px;
  position: relative;
  margin-bottom: 30px;
  padding: 0 22px; }
  .item-modal__images-full span {
    height: 100%;
    position: absolute;
    left: 22px;
    right: 22px;
    opacity: 0;
    transition: all 0.3s; }
    .item-modal__images-full span.is-active {
      opacity: 1; }
  .item-modal__images-full img {
    width: 100%;
    height: 100%;
    position: absolute;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
    font-family: "object-fit: contain; object-position: center"; }

.item-modal__images-prev,
.item-modal__images-next {
  position: absolute;
  transition: all 0.3s;
  opacity: 0.2;
  width: 8px;
  height: 16px;
  top: 50%;
  margin-top: -6px;
  border: 0; }
  .item-modal__images-prev:hover,
  .item-modal__images-next:hover {
    opacity: 0.6; }

.item-modal__images-prev {
  background: url(../img/arrow_prev.svg) no-repeat 0 0;
  background-size: contain;
  left: 0; }

.item-modal__images-next {
  background: url(../img/arrow_next.svg) no-repeat 0 0;
  background-size: contain;
  right: 0; }

.item-modal__images-thumbnails {
  font-size: 0;
  margin-right: -22px;
  margin-bottom: -20px; }
  .item-modal__images-thumbnails span {
    width: 80px;
    height: 80px;
    background: #FFFFFF;
    border: 1px solid #D6E7F3;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer; }
    .item-modal__images-thumbnails span img {
      width: 60px;
      height: 60px;
      -o-object-fit: contain;
      object-fit: contain;
      -o-object-position: center;
      object-position: center;
      font-family: "object-fit: contain; object-position: center"; }
    .item-modal__images-thumbnails span:hover, .item-modal__images-thumbnails span.is-active {
      border-color: #1F72CF;
      box-shadow: inset 0 0 0 1px #1F72CF; }

.item-modal__descr {
  font-size: 14px;
  max-width: 480px;
  position: relative;
  line-height: 1.5625; }

.item-modal__name {
  margin-bottom: 20px; }

.modal-items-slider {
  display: none; }

.modal-items-slider-init {
  margin-top: 0;
  padding-top: 40px;
  overflow: hidden; }
  .modal-items-slider-init:not(.slick-initialized) .sld:not(:first-child) {
    display: none; }
  .modal-items-slider-init:not(.slick-initialized) .sld:first-child {
    opacity: 0; }
  .modal-items-slider-init .slick-track {
    min-width: 100%; }
  .modal-items-slider-init .slick-list,
  .modal-items-slider-init .slick-track {
    transform: none; }
  .modal-items-slider-init .slick-list {
    overflow: visible; }
  .modal-items-slider-init .sld {
    width: 100%;
    line-height: 1; }
  .modal-items-slider-init .slick-slide {
    width: 100%;
    line-height: 1; }
  .modal-items-slider-init .slider-prev {
    left: 42.25%;
    top: 0;
    transform: none;
    z-index: 10; }
    .modal-items-slider-init .slider-prev:before {
      z-index: 1; }
  .modal-items-slider-init .slider-next {
    right: auto;
    left: calc(42.25% + 50px);
    top: 0;
    transform: none;
    z-index: 10; }
    .modal-items-slider-init .slider-next:before {
      z-index: 1; }

.modal--form {
  max-width: 580px;
  padding: 30px;
  padding-bottom: 74px;
  z-index: 0; }
  .modal--form:before {
    content: "";
    background: url(../img/modal_form_img.png) no-repeat 0 0;
    background-size: contain;
    width: 243px;
    height: 303px;
    bottom: 0;
    right: 0;
    z-index: -1;
    position: absolute; }

.modal--region {
  max-width: 580px;
  padding: 30px;
  z-index: 0; }

.region-modal .form__heading {
  margin-bottom: 24px; }

.region-modal .form-group {
  box-shadow: 0 5px 9px rgba(0, 0, 0, 0.09);
  margin-bottom: 25px;
  border-radius: 5px; }
  .region-modal .form-group .form-field {
    border-color: #dfdfe0; }

.region-modal__list {
  margin: 0;
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px; }
  .region-modal__list li {
    width: 50%;
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 16px;
    padding-right: 20px; }
    .region-modal__list li a {
      border-bottom-style: dashed; }
    .region-modal__list li:before {
      display: none; }

.modal--map {
  padding: 0;
  max-width: 800px;
  height: 450px; }

#map-container {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }

.modal--work {
  padding: 0;
  border-radius: 0;
  max-width: 800px;
  max-height: calc(100vh - 40px); }

.modal-work {
  position: relative; }

.modal-work__info {
  position: absolute;
  left: 30px;
  bottom: 30px;
  z-index: 1;
  border-radius: 5px;
  background: #fff;
  padding: 20px;
  max-width: 300px; }
  .modal-work__info .work-preview__tags {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    left: 0;
    bottom: 0;
    margin-right: 10px;
    background: #2cbf4f; }
  .modal-work__info .work-preview__speed {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    top: 0;
    left: 0;
    background: #2cbf4f; }
  .modal-work__info .work-preview__address {
    padding-top: 11px; }

.modal-work__images.slick-dotted {
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 1; }

.modal-work__images {
  overflow: hidden;
  font-size: 0;
  position: relative; }
  .modal-work__images img {
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    font-family: "object-fit: cover; object-position: center"; }
  .modal-work__images .slider-prev,
  .modal-work__images .slider-next {
    background: rgba(0, 0, 0, 0.59); }
    .modal-work__images .slider-prev:before,
    .modal-work__images .slider-next:before {
      opacity: 0.6; }
    .modal-work__images .slider-prev:hover,
    .modal-work__images .slider-next:hover {
      opacity: 1;
      background: rgba(0, 0, 0, 0.89); }
  .modal-work__images .slider-prev {
    left: 20px; }
  .modal-work__images .slider-next {
    right: 20px; }

.modal-form .form__body {
  margin: 0; }

.modal-form .form__note {
  margin: 0;
  text-align: left; }

.modal-form .form__heading p {
  max-width: 400px; }

.modal-form .form-field-container {
  margin-bottom: 13px; }

.modal-form .form-field-container--button {
  padding-right: 122px; }
  .modal-form .form-field-container--button .btn {
    width: 122px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1; }

.modal-form.is-submitted .btn {
  width: 100% !important;
  color: rgba(255, 255, 255, 0);
  border-radius: 5px; }

.modal-form.is-submitted .btn__success {
  display: flex; }

.swiper-pagination {
  bottom: 30px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 100px;
  font-size: 0;
  padding: 5px 6px;
  border-radius: 9px; }

.swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
  width: auto;
  left: 50%;
  transform: translateX(-50%); }

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 3px; }
  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet:first-child {
    margin-left: 0; }
  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet:last-child {
    margin-right: 0; }

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  opacity: 0.4;
  background: #FFFFFF; }

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #FFFFFF; }

@media (max-height: 810px) {
  .modal--work {
    max-width: 800px; } }

@media (max-height: 690px) {
  .modal--work {
    max-width: 800px; } }

@media (max-width: 1245px) {
  .modal:not(.modal--form):not(.modal--region):not(.modal--map):not(.modal--works):not(.modal--work) {
    max-width: 780px; }
  .modal-items-slider-init {
    padding-top: 60px; }
  .item-modal__left {
    width: 48.5%;
    max-width: 48.5%;
    margin-top: -60px; }
  .item-modal__right {
    width: 51.5%;
    max-width: 51.5%;
    margin-top: -20px; }
  .item-modal__images-full {
    height: 305px; }
  .modal:not(.modal--form):not(.modal--region):not(.modal--work) {
    padding: 41px; }
  .modal--work {
    max-width: 816px; }
  .modal-work__info {
    left: 20px;
    bottom: 20px; }
  .swiper-pagination {
    bottom: 20px; }
  .modal--item {
    padding-left: 100px; }
  .modal-items-slider-init .slider-prev {
    left: 48.5%; }
  .modal-items-slider-init .slider-next {
    left: calc(48.5% + 61px); } }

@media (max-width: 945px) {
  .modal:not(.modal--form):not(.modal--region):not(.modal--map):not(.modal--works):not(.modal--work) {
    max-width: 100%;
    min-height: 100vh;
    margin-left: 0;
    border-radius: 0;
    padding: 20px;
    padding-top: 85px; }
    .modal:not(.modal--form):not(.modal--region):not(.modal--map):not(.modal--works):not(.modal--work) .fancybox-close-small {
      right: 30px;
      top: 30px;
      color: #b2b2b2; }
      .modal:not(.modal--form):not(.modal--region):not(.modal--map):not(.modal--works):not(.modal--work) .fancybox-close-small:hover {
        color: #000; }
  .modal--item {
    max-width: 100%; }
  .modal--work .fancybox-close-small {
    right: 0;
    top: -60px; }
  .modal--map {
    max-width: 650px;
    height: 410px; }
  .item-modal {
    max-width: 680px;
    margin: 0 auto; }
  .fancybox-slide--html .fancybox-content:not(.modal--form) {
    margin-left: 0; }
  .item-modal__left {
    width: 48.2%;
    max-width: 48.2%; }
  .item-modal__right {
    width: 51.8%;
    max-width: 51.8%;
    padding-left: 20px; }
  .modal-items-slider-init .slider-prev {
    left: calc(48.2% + 20px); }
  .modal-items-slider-init .slider-next {
    left: calc(48.2% + 81px); } }

@media (max-width: 745px) {
  .modal--form, .modal--region, .modal--map {
    max-width: 100%;
    min-height: 100vh;
    margin-left: 0;
    border-radius: 0;
    padding: 20px;
    padding-top: 56px; }
    .modal--form .form, .modal--region .form, .modal--map .form {
      max-width: 380px; }
    .modal--form .fancybox-close-small, .modal--region .fancybox-close-small, .modal--map .fancybox-close-small {
      right: 8px;
      top: 8px;
      color: #b2b2b2; }
      .modal--form .fancybox-close-small:hover, .modal--region .fancybox-close-small:hover, .modal--map .fancybox-close-small:hover {
        color: #000; }
  .modal:not(.modal--form):not(.modal--region):not(.modal--work) .fancybox-close-small {
    right: 8px;
    top: 8px; }
  .fancybox-slide--html .fancybox-content.modal--form {
    margin-left: 0; }
  .modal:not(.modal--work) {
    padding-top: 60px; }
  .modal--map {
    padding: 0; }
    .modal--map .fancybox-close-small {
      background: #fff;
      right: 0;
      top: 0; }
  .fancybox-close-small {
    right: 8px;
    top: 8px; }
  .item-modal {
    display: block; }
  .item-modal__left {
    width: 100%;
    max-width: 100%;
    padding-right: 0;
    padding-top: 0px;
    margin-bottom: 30px;
    margin-top: 0; }
    .item-modal__left .item-modal__controls,
    .item-modal__left .item-modal__name {
      display: block; }
  .item-modal__right {
    width: 100%;
    max-width: 100%;
    padding-left: 0;
    padding-top: 0;
    margin-top: 0; }
    .item-modal__right .item-modal__controls,
    .item-modal__right .item-modal__name {
      display: none; }
  .modal-items-slider-init .slider-prev {
    left: 0; }
  .modal-items-slider-init .slider-next {
    left: 50px; }
  .item-modal__images-full {
    margin-bottom: 20px;
    height: 305px; }
  .item-modal__descr {
    max-width: 100%; }
  .region-modal__list {
    display: block; }
    .region-modal__list li {
      width: 100%;
      padding: 0; }
  .modal-items-slider-init {
    overflow: visible; }
  .modal-form .form-field-container--button {
    padding-right: 49px; }
    .modal-form .form-field-container--button .btn--arrow {
      width: 49px; }
    .modal-form .form-field-container--button .btn__success {
      font-size: 11px; }
  .modal-form.is-submitted .btn--arrow:before {
    opacity: 0; }
  .modal-work__info {
    position: relative;
    left: 0;
    bottom: 0;
    max-width: 100%;
    border-radius: 0; }
  .modal--work {
    background: none;
    height: 100%;
    padding-top: 66px;
    vertical-align: top; }
    .modal--work .fancybox-close-small {
      top: 10px; } }

.blog-popular {
  padding-bottom: 80px; }
  @media (max-width: 745px) {
    .blog-popular {
      padding-bottom: 40px; } }

.blog-popular__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 48px; }
  @media (max-width: 745px) {
    .blog-popular__header {
      margin-bottom: 30px; } }

.popular-articles {
  margin-right: -20px;
  display: flex;
  flex-wrap: wrap; }
  .popular-articles .article-preview {
    width: calc(33.33% - 20px);
    margin: 0;
    margin-right: 20px;
    margin-bottom: 20px; }
  @media (max-width: 945px) {
    .popular-articles .article-preview {
      width: calc(50% - 20px); } }
  @media (max-width: 745px) {
    .popular-articles {
      margin-right: 0; }
      .popular-articles .article-preview {
        width: 100%;
        margin-right: 0; } }

.article-preview--image {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  border: 0;
  position: relative;
  height: 260px;
  z-index: 0;
  padding: 20px;
  padding-bottom: 24px;
  border-radius: 4px;
  overflow: hidden; }
  .article-preview--image .article-preview__image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 4px; }
    .article-preview--image .article-preview__image:before {
      display: none; }
    .article-preview--image .article-preview__image:after {
      content: "";
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 44%, rgba(0, 0, 0, 0.65) 82%);
      transition: all 0.3s;
      opacity: 0.8; }
  .article-preview--image .work-preview__tags {
    margin-bottom: 6px;
    position: relative;
    left: 0;
    bottom: 0;
    background: rgba(0, 199, 103, 0.7);
    display: inline-flex;
    transform: translateZ(0);
    z-index: 1;
    backface-visibility: hidden; }
  .article-preview--image .article-preview__name {
    margin-bottom: 4px;
    position: relative;
    transform: translateZ(0);
    z-index: 1;
    backface-visibility: hidden; }
    .article-preview--image .article-preview__name span {
      font-weight: bold;
      font-size: 20px;
      color: #FFFFFF;
      letter-spacing: 0.12px;
      line-height: 1.25; }
  .article-preview--image .article-preview__date {
    font-size: 10px;
    line-height: 20px;
    letter-spacing: 0.06px;
    color: #CECECE;
    margin: 0; }
  .article-preview--image:hover {
    backface-visibility: hidden; }
    .article-preview--image:hover .article-preview__image:after {
      opacity: 1; }
  @media (max-width: 1245px) {
    .article-preview--image {
      height: 190px;
      padding-bottom: 15px; }
      .article-preview--image .article-preview__image:after {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.7) 82%); }
      .article-preview--image .article-preview__name span {
        font-size: 16px; } }
  @media (max-width: 945px) {
    .article-preview--image {
      height: 220px; } }
  @media (max-width: 745px) {
    .article-preview--image {
      height: 0;
      min-height: 190px;
      padding-bottom: 56%; }
      .article-preview--image .article-preview__body {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 20px;
        padding-bottom: 15px; } }

.section-socials {
  background: #F4F6F9;
  padding-top: 60px;
  padding-bottom: 30px; }

.section-socials__cols {
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 0 auto; }

.section-socials__col {
  width: 25%;
  text-align: center;
  padding-bottom: 30px; }

.section-social {
  color: #3C3C3C; }

.section-social__icon {
  display: block;
  width: 60px;
  height: 60px;
  position: relative;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 15px;
  transition: all 0.3s;
  border: 0; }
  .section-social__icon:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat; }
  .section-social__icon--vk {
    background: #4C6C91; }
    .section-social__icon--vk:before {
      background-image: url("../img/vk-icon.svg"); }
    .section-social__icon--vk:hover {
      background: #6385ad; }
  .section-social__icon--ok {
    background: #F68634; }
    .section-social__icon--ok:before {
      background-image: url("../img/ok-icon.svg"); }
    .section-social__icon--ok:hover {
      background: #f8a365; }
  .section-social__icon--fb {
    background: #3B5998; }
    .section-social__icon--fb:before {
      background-image: url("../img/fb-icon.svg"); }
    .section-social__icon--fb:hover {
      background: #4c70ba; }
  .section-social__icon--inst {
    background-image: linear-gradient(165deg, #4E60D3 0%, #913BAF 14%, #D52D88 76%, #F26D4F 100%); }
    .section-social__icon--inst:before {
      background-image: url("../img/inst-icon.svg"); }
    .section-social__icon--inst:hover {
      opacity: 0.9; }

.section-social__count {
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.09px;
  line-height: 19px;
  margin-bottom: -4px; }

.section-social__subs {
  font-size: 11px;
  letter-spacing: 0.06px;
  text-align: center;
  line-height: 19px;
  margin-bottom: 17px; }

.blog-collections {
  background: #F4F6F9;
  padding-top: 36px;
  padding-bottom: 40px; }

.blog-collections__title {
  margin-bottom: 47px;
  opacity: 0.5;
  font-weight: 600;
  font-size: 20px;
  color: #254C56;
  letter-spacing: 0.12px;
  line-height: 26px; }

.blog-collections-cols {
  display: flex;
  flex-wrap: wrap; }

.blog-collections-col {
  width: 25%;
  padding-right: 15px;
  padding-bottom: 40px; }

.blog-collection {
  position: relative;
  padding-left: 60px; }
  .blog-collection:before {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    left: 0;
    top: 4px;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain; }
  .blog-collection--instrukcii:before {
    background-image: url("../img/blog_collection_icon1.svg"); }
  .blog-collection--razbor:before {
    background-image: url("../img/blog_collection_icon2.svg"); }
  .blog-collection--polezno:before {
    background-image: url("../img/blog_collection_icon3.svg"); }
  .blog-collection--interesno:before {
    background-image: url("../img/blog_collection_icon4.svg"); }

.blog-collection__title {
  font-weight: bold;
  display: inline-block;
  font-size: 26px;
  letter-spacing: 0;
  line-height: 42px;
  margin-bottom: 20px;
  transition: all 0.3s;
  border: 0;
  padding: 0; }

.blog-collection__links {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  max-width: 216px; }
  .blog-collection__links li {
    margin-bottom: 15px;
    padding: 0; }
    .blog-collection__links li:before {
      display: none; }
    .blog-collection__links li a {
      display: inline-block;
      border: 0; }

.article-preview--blog .article-preview__image {
  height: 169px; }

.article-preview--blog .article-preview__name a {
  border: 0; }

.article-preview--blog .article-preview__date {
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 0.06px; }
  .article-preview--blog .article-preview__date:last-child {
    padding-top: 6px;
    margin: 0; }

.blog-articles-slider {
  position: relative;
  margin-bottom: -15px; }
  .blog-articles-slider .swiper-container:not(.swiper-container-initialized) .swiper-wrapper .article-preview {
    display: none; }
  .blog-articles-slider .swiper-container {
    margin-right: -20px; }
  .blog-articles-slider .swiper-button-prev,
  .blog-articles-slider .swiper-button-next {
    top: -81px;
    margin: 0;
    transform: translate(0); }
  .blog-articles-slider .swiper-button-prev {
    left: auto;
    right: 55px; }
  .blog-articles-slider .swiper-button-next {
    right: 0; }
  .blog-articles-slider .swiper-slide {
    display: flex;
    flex-wrap: wrap; }
  .blog-articles-slider .article-preview {
    width: calc(25% - 20px);
    margin: 0;
    margin-right: 20px;
    margin-bottom: 15px; }

@media (max-width: 1245px) {
  .blog-articles-slider .article-preview {
    width: calc(33% - 20px); }
  .blog-collections-col {
    width: 50%; } }

@media (max-width: 945px) {
  .blog-articles-slider .article-preview {
    width: calc(50% - 20px); } }

@media (max-width: 745px) {
  .blog-articles-slider {
    margin-right: 0; }
    .blog-articles-slider .swiper-button-prev,
    .blog-articles-slider .swiper-button-next {
      display: none; }
    .blog-articles-slider .swiper-slide {
      width: 270px; }
    .blog-articles-slider .article-preview {
      width: 280px;
      margin-right: 0; }
  .blog-collections {
    padding-bottom: 0; }
  .blog-collections-col {
    width: 100%;
    padding-right: 0; }
  .blog-collection__links {
    max-width: none; }
  .section-socials__col {
    width: 50%; } }

.share-socials li {
  margin-bottom: 10px;
  padding: 0; }
  .share-socials li:before {
    display: none; }

.share-button {
  display: block;
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 15px;
  transition: all 0.3s;
  border: 0; }
  .share-button:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain; }
  .share-button--vk {
    background: #4C6C91; }
    .share-button--vk:before {
      background-image: url("../img/vk-icon.svg");
      width: 21px;
      height: 11px; }
    .share-button--vk:hover {
      background: #6385ad; }
  .share-button--ok {
    background: #F68634; }
    .share-button--ok:before {
      background-image: url("../img/ok-icon.svg");
      width: 12px;
      height: 21px; }
    .share-button--ok:hover {
      background: #f8a365; }
  .share-button--fb {
    background: #3B5998; }
    .share-button--fb:before {
      background-image: url("../img/fb-icon.svg");
      width: 11px;
      height: 22px; }
    .share-button--fb:hover {
      background: #4c70ba; }

.article-blog {
  padding-bottom: 45px; }

.article-blog-cover {
  height: 400px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 0;
  color: #fff;
  padding-bottom: 30px;
  text-align: center;
  margin-bottom: 50px; }
  .article-blog-cover .work-preview__tags {
    position: relative;
    left: 0;
    bottom: 0;
    background: rgba(0, 199, 103, 0.7);
    display: inline-flex; }

.article-blog-cover__image {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  font-size: 0;
  position: absolute; }
  .article-blog-cover__image:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 34%, rgba(0, 0, 0, 0.5) 76%);
    transition: all 0.3s; }
  .article-blog-cover__image img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    font-family: "object-fit: cover; object-position: center"; }

.article-blog-cover__title {
  font-weight: 600;
  font-size: 39px;
  letter-spacing: 0.23px;
  line-height: 50px;
  margin-bottom: 16px; }

.article-blog-cover__author-date {
  font-size: 14px;
  color: #CECECE;
  letter-spacing: 0.08px;
  text-align: center;
  line-height: 20px;
  margin-bottom: 15px; }

.article-blog-cover__author {
  font-weight: bold;
  display: inline-block;
  vertical-align: middle; }

.article-blog-cover__date {
  display: inline-block;
  vertical-align: middle; }

.blog-article-container {
  padding: 0 200px;
  position: relative; }

.p-article {
  position: relative; }

.p-article-block {
  position: absolute;
  width: 180px;
  left: 100%;
  margin-left: 20px;
  top: 0;
  font-weight: bold;
  font-size: 16px;
  color: #004DA3;
  letter-spacing: 0.09px;
  line-height: 26px;
  border: 0; }
  .p-article-block img {
    display: block;
    margin: 0;
    margin-bottom: 8px; }

.article-slider {
  margin: 28px 0; }
  .article-slider img {
    margin: 0; }

.reviews__item--article {
  background: #F4F6F9;
  margin: 28px 0;
  padding: 30px;
  padding-right: 25px;
  display: flex; }
  .reviews__item--article .review__item-reviewed {
    display: none; }
  .reviews__item--article .reviews__item-img {
    display: block;
    border: 0;
    margin-bottom: 15px; }
  .reviews__item--article .reviews__item-rating {
    margin: 0; }
  .reviews__item--article img {
    margin: 0; }
  .reviews__item--article figure {
    margin: 0; }
  .reviews__item--article .reviews__item-content {
    font-size: 16px;
    letter-spacing: 0.09px;
    line-height: 22px;
    margin-top: -8px; }
    .reviews__item--article .reviews__item-content p {
      margin-bottom: 37px; }

.reviews__item-left {
  width: 270px;
  padding-right: 50px;
  flex: 1 0 auto; }

.article-slider {
  position: relative; }
  .article-slider:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 71%, rgba(0, 0, 0, 0.5) 90%); }

.article-slider__controls {
  width: 156px;
  height: 40px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center; }
  .article-slider__controls .swiper-button-prev {
    transform: translateX(0);
    left: 0;
    top: 0;
    margin: 0; }
  .article-slider__controls .swiper-button-next {
    transform: translateX(0);
    right: 0;
    top: 0;
    margin: 0; }
  .article-slider__controls .swiper-pagination {
    position: relative;
    bottom: 0;
    margin: 0;
    transform: translateX(0);
    font-weight: 400;
    background: none;
    font-size: 14px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 24px;
    left: 0; }

.article-conclusion {
  font-size: 24px;
  color: #000000;
  letter-spacing: 0;
  line-height: 34px;
  padding-top: 8px; }
  .article-conclusion h2 {
    font-size: 26px;
    margin-bottom: 14px;
    letter-spacing: 0.15px;
    line-height: 1.692308; }

.article-form {
  background: url("../img/article_form.jpg") no-repeat center;
  background-size: cover;
  padding: 27px;
  padding-bottom: 55px;
  margin: 47px 0;
  margin-top: 56px;
  border-radius: 14px; }
  .article-form .form-request .form__heading {
    color: #000;
    margin-bottom: 27px; }
    .article-form .form-request .form__heading .form_title {
      margin: 0;
      font-weight: 600;
      font-size: 28px;
      letter-spacing: 0.16px;
      line-height: 1.285714;
      margin-bottom: 5px; }
    .article-form .form-request .form__heading p {
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.09px;
      line-height: 20px; }
  .article-form .form-request .form-field {
    height: 49px;
    border: 1px solid #DFDFE0; }
  .article-form .form-request .form__button {
    margin-bottom: 16px; }
  .article-form .form-request .btn--lg {
    padding: 17px 42px; }

.b-why__good--article {
  margin-top: 54px;
  margin-bottom: 48px;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 5px;
  padding: 20px 26px; }
  .b-why__good--article:before {
    display: none; }
  .b-why__good--article .b-why__logo {
    width: 100%;
    margin-bottom: 36px; }
  .b-why__good--article .b-why__heading {
    margin-bottom: 21px;
    letter-spacing: 0.16px; }
  .b-why__good--article .why-features__item {
    letter-spacing: 0.09px;
    font-size: 16px; }
    .b-why__good--article .why-features__item small {
      letter-spacing: 0.08px; }
  .b-why__good--article .b-why__left {
    width: 65%;
    position: relative; }
    .b-why__good--article .b-why__left:after {
      content: "";
      display: block;
      width: 421px;
      height: 237px;
      background-image: url("../img/b_why_image@2x.png");
      background-size: contain;
      background-repeat: no-repeat;
      z-index: -1; }
  .b-why__good--article .b-why__right {
    width: 35%;
    padding-top: 15px; }
  .b-why__good--article .why-features__list {
    padding-right: 0;
    width: 100%;
    margin-bottom: 0; }
  .b-why__good--article .kit-constructor__total {
    opacity: 1; }
  .b-why__good--article .kit-constructor__plus-week-testing {
    color: #fff;
    opacity: 0.6;
    transition: opacity 0.3s; }
    .b-why__good--article .kit-constructor__plus-week-testing span {
      border-bottom-color: #fff; }
    .b-why__good--article .kit-constructor__plus-week-testing:hover {
      opacity: 1; }

.b-article-rating {
  padding-top: 60px;
  padding-bottom: 53px;
  text-align: center; }

.rating {
  color: #000000;
  text-align: center; }

.rating__stars {
  display: flex;
  justify-content: center;
  margin-bottom: 19px; }
  .rating__stars span {
    font-size: 0;
    width: 0; }
  .rating__stars[data-hover-rating="1"] .rating__star:nth-child(1):after, .rating__stars[data-rating="1"] .rating__star:nth-child(1):after {
    opacity: 1; }
  .rating__stars[data-hover-rating="2"] .rating__star:nth-child(1):after,
  .rating__stars[data-hover-rating="2"] .rating__star:nth-child(2):after, .rating__stars[data-rating="2"] .rating__star:nth-child(1):after,
  .rating__stars[data-rating="2"] .rating__star:nth-child(2):after {
    opacity: 1; }
  .rating__stars[data-hover-rating="3"] .rating__star:nth-child(1):after,
  .rating__stars[data-hover-rating="3"] .rating__star:nth-child(2):after,
  .rating__stars[data-hover-rating="3"] .rating__star:nth-child(3):after, .rating__stars[data-rating="3"] .rating__star:nth-child(1):after,
  .rating__stars[data-rating="3"] .rating__star:nth-child(2):after,
  .rating__stars[data-rating="3"] .rating__star:nth-child(3):after {
    opacity: 1; }
  .rating__stars[data-hover-rating="4"] .rating__star:nth-child(1):after,
  .rating__stars[data-hover-rating="4"] .rating__star:nth-child(2):after,
  .rating__stars[data-hover-rating="4"] .rating__star:nth-child(3):after,
  .rating__stars[data-hover-rating="4"] .rating__star:nth-child(4):after, .rating__stars[data-rating="4"] .rating__star:nth-child(1):after,
  .rating__stars[data-rating="4"] .rating__star:nth-child(2):after,
  .rating__stars[data-rating="4"] .rating__star:nth-child(3):after,
  .rating__stars[data-rating="4"] .rating__star:nth-child(4):after {
    opacity: 1; }
  .rating__stars[data-hover-rating="5"] .rating__star:nth-child(1):after,
  .rating__stars[data-hover-rating="5"] .rating__star:nth-child(2):after,
  .rating__stars[data-hover-rating="5"] .rating__star:nth-child(3):after,
  .rating__stars[data-hover-rating="5"] .rating__star:nth-child(4):after,
  .rating__stars[data-hover-rating="5"] .rating__star:nth-child(5):after, .rating__stars[data-rating="5"] .rating__star:nth-child(1):after,
  .rating__stars[data-rating="5"] .rating__star:nth-child(2):after,
  .rating__stars[data-rating="5"] .rating__star:nth-child(3):after,
  .rating__stars[data-rating="5"] .rating__star:nth-child(4):after,
  .rating__stars[data-rating="5"] .rating__star:nth-child(5):after {
    opacity: 1; }
  .rating__stars[data-rating="0"] .rating__star {
    cursor: pointer; }

.rating__star {
  width: 48px;
  height: 40px;
  position: relative;
  user-select: none; }
  .rating__star:before, .rating__star:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.3s; }
  .rating__star:before {
    background-image: url("../img/rating_star_border.svg"); }
  .rating__star:after {
    background-image: url("../img/rating_star_filled.svg");
    opacity: 0; }

.rating__total {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.12px;
  line-height: 26px;
  margin-bottom: 5px; }

.rating__ratings {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px; }

.b-article-tags {
  background: #F4F6F9;
  padding-top: 60px;
  padding-bottom: 40px;
  text-align: center; }

.article-tags-wrapper {
  max-width: 800px;
  margin: 0 auto; }

.article-tags {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  justify-content: center; }

.article-tag {
  display: flex;
  align-items: center;
  height: 55px;
  border: 1px solid #004DA3;
  border-radius: 27.5px;
  text-align: center;
  padding: 10px 30px;
  margin-right: 20px;
  margin-bottom: 20px;
  transition: all 0.3s;
  opacity: 0.6;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 0.06px;
  text-transform: uppercase; }
  .article-tag:hover {
    opacity: 1;
    border: 1px solid #004DA3; }

.b-recommended-articles {
  padding-bottom: 64px; }

.recommend-articles-slider .article-preview {
  margin: 0;
  width: 100%; }

.recommend-articles-slider .swiper-button-prev,
.recommend-articles-slider .swiper-button-next {
  top: 70px;
  margin: 0;
  transform: translate(0); }

.recommend-articles-slider .swiper-button-prev {
  left: 10px; }

.recommend-articles-slider .swiper-button-next {
  right: 10px; }

.recommend-articles-slider:not(.swiper-container-initialized) .swiper-slide {
  display: none; }

@media (max-width: 1245px) {
  .blog-article-container {
    padding-left: 80px; }
  .b-why__good--article {
    padding: 20px; }
    .b-why__good--article .b-why__left {
      width: 100%;
      margin-bottom: 0px; }
    .b-why__good--article .b-why__right {
      width: 100%;
      padding-left: 0; } }

@media (max-width: 945px) {
  .blog-article-container {
    padding-right: 0; }
  .p-article-block {
    position: relative;
    left: 0;
    top: 0;
    margin: 0;
    margin-top: 20px;
    display: block; } }

@media (min-width: 746px) {
  .blog-article-socials-container {
    width: 40px;
    height: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0; }
  .blog-article-socials {
    position: absolute;
    bottom: 0;
    top: -80px;
    left: 0; }
  .share-socials {
    position: absolute;
    top: 80px;
    margin: 0; }
    .share-socials.is-fixed-top {
      position: fixed; }
    .share-socials.is-fixed-bottom {
      top: auto;
      bottom: 0; } }

@media (max-width: 745px) {
  .blog-article-container {
    padding-left: 0; }
  .article-tag {
    font-size: 10px;
    height: 40px;
    padding: 5px 20px; }
  .recommend-articles-slider {
    overflow: visible; }
    .recommend-articles-slider .swiper-button-prev,
    .recommend-articles-slider .swiper-button-next {
      display: none; }
    .recommend-articles-slider .swiper-slide {
      width: 270px !important; }
  .article-blog-cover {
    margin-left: -20px;
    margin-right: -20px;
    height: 340px;
    margin-bottom: 40px; }
  .article-blog-cover__title {
    font-size: 24px;
    line-height: 30px; }
  .article-conclusion {
    font-size: 20px;
    line-height: 28px; }
  .b-why__good--article {
    margin-left: -20px;
    margin-right: -20px;
    border-radius: 0; }
  .reviews__item-left {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px; }
  .reviews__item--article {
    display: block;
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px; }
  .reviews__item-right {
    width: 100%; }
  .article-form {
    margin-left: -20px;
    margin-right: -20px;
    border-radius: 0;
    padding: 20px;
    padding-bottom: 30px; }
    .article-form .form-request .form__heading .form_title {
      font-size: 24px; }
    .article-form .form-request .form__heading p {
      margin-bottom: 0; }
  .share-socials {
    display: flex; }
    .share-socials li {
      margin-bottom: 0px;
      margin-right: 10px; } }

@media (max-width: 517px) {
  .b-why__good--article .b-why__left:after {
    width: 100%;
    height: 0;
    padding-bottom: 56%; } }
